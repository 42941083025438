import { TitleSection } from "../components";
import Team from "../components/common/team";
import { DefaultLayout } from "../layouts";

export default function OurTeamPage() {
  return (
    <DefaultLayout>
      <TitleSection title="Our Team" bg="/images/teams-bg.jpg" />
      <Team />
    </DefaultLayout>
  );
}
