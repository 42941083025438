import React, { RefObject, useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import { Button } from "../common";
import Carousel from "nuka-carousel";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import useWindowWidth from "../../hooks/useWindowWidth";
// import { Link } from 'react-router-dom';

interface ServiceItemProps {
  description: string;
  title: string;
  image: string;
  link: string;
}

const ServiceItem: React.FC<ServiceItemProps> = ({
  description,
  title,
  image,
  link,
}) => {
  const navigate = useNavigate();
  return (
    <div className="mx-2 h-full relative hover:scale-95 transition-transform ease-in-out">
      <img
        src={image}
        alt=""
        className="w-full h-60 object-cover object-center rounded-lg mb-3"
      />
      <h2 className="text-xl md:text-2xl mb-3">{title}</h2>
      <p className="mb-16">{description}</p>
      <Button
        onClick={() => navigate(link)}
        className="absolute left-0 bottom-0"
      >
        Learn More
      </Button>
    </div>
  );
};

interface servicesProps {
  scrollRef: RefObject<HTMLDivElement>;
}

const Services: React.FC<servicesProps> = ({ scrollRef }) => {
  const width = useWindowWidth();
  const [noOfSlides, setNoOfSlides] = useState(3);

  useEffect(() => {
    if (width < 768) {
      setNoOfSlides(1);
    } else if (width < 1024) {
      setNoOfSlides(2);
    } else {
      setNoOfSlides(3);
    }
  }, [width]);

  return (
    <section className="services-section py-16 lg:py-24" ref={scrollRef}>
      <div className="container mx-auto px-6">
        <Fade direction="up" triggerOnce>
          <h2 className="text-4xl lg:text-5xl mb-8">Services</h2>
        </Fade>
        <Fade direction="up" triggerOnce>
          <Carousel
            slidesToShow={noOfSlides}
            autoplay
            autoplayInterval={3000}
            wrapAround
            defaultControlsConfig={{
              nextButtonText: <FaArrowRight />,
              nextButtonStyle: {
                backgroundColor: "#fe0000",
                borderRadius: "50px",
                position: "absolute",
                top: "-75px",
                right: 0,
              },
              prevButtonText: <FaArrowLeft />,
              prevButtonStyle: {
                backgroundColor: "#fe0000",
                borderRadius: "50px",
                position: "absolute",
                top: "-75px",
                right: "45px",
              },
              pagingDotsStyle: { display: "none" },
            }}
          >
            <ServiceItem
              link="/first-home"
              title="First Home"
              image="/images/services/first-home.jfif"
              description="Your first home purchase should be exciting, not overwhelming. We're here to guide you through every step, ensuring a memorable and stress-free experience."
            />

            <ServiceItem
              link="/refinance"
              title="Refinance"
              image="/images/services/refinance.jfif"
              description="Optimize your mortgage with our refinancing services. Lower your interest rates, adjust your payment terms, and achieve better financial flexibility."
            />

            <ServiceItem
              link="/debt-consolidation"
              title="Debt Consolidation"
              image="/images/services/debt-consolidation.jfif"
              description="Streamline your finances with our debt consolidation options. Combine your debts into one manageable mortgage, reducing your interest rates and simplifying your life."
            />

            <ServiceItem
              link="/bridge-financing"
              title="Bridge Financing"
              image="/images/services/bridge-financing.jfif"
              description="We provide the necessary funds to bridge the gap, offering short-term financing solutions that align with your timelines, ensuring you never miss out on a property opportunity."
            />

            <ServiceItem
              link="/heloc"
              title="HELOC"
              image="/images/services/heloc.jfif"
              description="Finance your dreams, from renovations to education. Our HELOC solutions offer the flexibility and convenience you need, with competitive rates and easy access to funds."
            />

            <ServiceItem
              link="/private-mortgages"
              title="Private Mortgages"
              image="/images/services/private-mortgage.jfif"
              description="Our private mortgages provide flexible options for those who may not meet traditional lending criteria. For those with unique lending needs, our Private Mortgages offer a solution. "
            />
            <ServiceItem
              link="business-loan"
              title="Business Loan"
              image="/images/services/business-loan.jfif"
              description="Fuel your business growth with our tailored loans. Get the capital you need for your business endeavors with our strategic financial advice. "
            />
            <ServiceItem
              link="investment-property"
              title="Investment Property"
              image="/images/services/investment-property.jfif"
              description="Invest with intelligence. Leverage our expertise to find the right financing for your investment properties, maximizing your returns."
            />
          </Carousel>
        </Fade>
      </div>
    </section>
  );
};

export default Services;
