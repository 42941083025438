import AboutCommercial from "./AboutCommercial";
import CommercialTypes from "./CommercialTypes";

export default function CommercialScreen() {
  return (
    <>
      <AboutCommercial />
      <CommercialTypes />
    </>
  );
}
