import { useNavigate } from "react-router-dom";
import { Button } from "../common";
import { RiUser2Line, RiHandCoinLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import { PagesModel } from "../../service";
import { Fade } from "react-awesome-reveal";

const AboutUs = () => {
  const navigate = useNavigate();
  const [aboutText, setAboutText] = useState(`
  Delta Mortgage Inc. is the premier destination for all your
            financial needs. With a holistic approach to wealth management, our
            team of highly qualified mortgage experts, insurance specialists,
            and investment advisors are dedicated to providing unparalleled
            service and tailored solutions. With years of experience in the
            industry, we have cultivated a reputation for excellence and
            expertise, empowering our clients to achieve their financial goals
            and secure their future. Whether you're a first-time homebuyer, a
            seasoned investor, or seeking comprehensive insurance coverage,
            Delta Mortgage Company is your trusted partner for success in every
            step of your financial journey.`);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await PagesModel.get("about");
        setAboutText(response.data.data.description);
      } catch (error: any) {
        console.log("Error while fetching the data:", error.message);
      }
    };
    fetchData();
  });

  return (
    <section className="py-16 lg:py-24">
      <div className="container mx-auto px-6">
        <Fade direction="up" delay={300} triggerOnce>
          <h2 className="text-4xl mb-6">The Leading Mortgage Providers</h2>
          <p className="mb-6">{aboutText}</p>
          <img src="/images/aboutus2.png" alt="" className="w-full my-12" />
          <p className="mb-6">{aboutText}</p>
        </Fade>
      </div>
    </section>
  );
};

export default AboutUs;
