import axios from "axios";

export const WORDPRESS_URL = "https://deltaf.ca/";
export const BACKEND_URL = "https://api.deltaf.ca/api/";

// export const BACKEND_URL = "http://localhost:4002/api/";
export const ASSETS_URL = "https://mortgageimage.s3.eu-west-2.amazonaws.com/";


export default axios;
