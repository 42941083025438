import { Fade } from "react-awesome-reveal";
import GetInTouchSection from "../GetInTouchSection";

export default function ConstructionLoanScreen() {
  return (
    <>
      <section className="px-6 py-16 lg:py-24">
        <div className="container mx-auto">
          <Fade direction="up" triggerOnce>
            <h2 className="text-4xl lg:text-5xl mb-4">Construction Loan</h2>
            <p className="mb-10">
              Build your dream home with our construction loans, designed to
              match your project's timeline and budget, with support that sees
              you through to completion.
              <br />
              <br />
              Building a home requires a special type of mortgage. Our
              construction loans provide the funds you need during the building
              phase, with interest-only payments and flexible draw schedules.
              Once construction is complete, we'll convert your loan into a
              traditional mortgage.
            </p>
          </Fade>
        </div>
      </section>
      <GetInTouchSection />
    </>
  );
}
