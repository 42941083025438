import Carousel from "nuka-carousel";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Fade } from "react-awesome-reveal";
import { TestimonialModel } from "../../service";
import { useEffect, useState } from "react";

type Testimonial = {
  name: string;
  review: string;
  image: string;
};

const Testimonials = () => {
  const [data, setData] = useState<Testimonial[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        let response = await TestimonialModel.get("testimonialsGetAll");
        let list = response.data.Data;

        let allList: Testimonial[] = [];

        list.map((value: any) => {
          let obj: Testimonial = {
            name: value.name,
            review: value.review,
            image: value.image,
          };

          allList.push(obj);
        });

        setData(allList);
      } catch (error: any) {
        console.log("Error while fetching the data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="py-16 lg:py-20">
      <div className="container mx-auto px-6">
        <Fade direction="up" triggerOnce>
          <h2 className="text-4xl lg:text-5xl mb-8 w-2/3 md:w-full">
            What Our Clients Say
          </h2>
        </Fade>
        {loading ? (
          <></>
        ) : (
          <div className="w-full">
            <Fade delay={300} triggerOnce>
              <Carousel
                defaultControlsConfig={{
                  nextButtonText: <FaArrowRight />,
                  nextButtonStyle: {
                    backgroundColor: "#fe0000",
                    borderRadius: "50px",
                    position: "absolute",
                    top: "-75px",
                    right: 0,
                  },
                  prevButtonText: <FaArrowLeft />,
                  prevButtonStyle: {
                    backgroundColor: "#fe0000",
                    borderRadius: "50px",
                    position: "absolute",
                    top: "-75px",
                    right: "45px",
                  },
                  pagingDotsStyle: { display: "none" },
                }}
                style={{ padding: "10px 20px" }}
                autoplay
                autoplayInterval={4000}
                wrapAround
              >
                {data.map((item, i) => {
                  return (
                    <div key={i} className="mx-5">
                      <p className="text-lg italic text-gray-600">
                        "{item.review}"
                      </p>
                      <div className="flex items-center gap-3 mt-5">
                        <img
                          src={"/images/avatar.png"}
                          alt={item.name}
                          className="w-12 h-12 object-cover rounded-full"
                        />
                        <p className="text-lg font-semibold">{item.name}</p>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </Fade>
          </div>
        )}
      </div>
    </div>
  );
};

export default Testimonials;
