import React from "react";

interface ButtonProps {
  // children: React.ReactNode;
  onClick?: () => void;
  variant?: String;
  size?: String;
  type?: "button" | "submit";
  fullWidth?: boolean;
  className?: String;
  isDisabled?: boolean;
}
const phone = {
  phone: "905-216-0061",
  label: "Lets talk",
};
const HeaderCallButton: React.FC<ButtonProps> = ({
  // children,
  variant,
  size,
  onClick,
  type = "button",
  fullWidth = false,
  className = "",
  isDisabled = false,
}) => {
  let buttonClassName = "";
  let sizeClassName = "py-3 px-6";
  switch (variant) {
    case "primary":
      buttonClassName =
        "border-2 border-cred-100 bg-cred-100 text-white hover:bg-neutral-900 hover:border-neutral-900";
      break;
    case "secondary":
      buttonClassName =
        "border-2 border-neutral-900 bg-neutral-900 text-white hover:bg-white hover:text-cred-100 hover:border-cred-100";
      break;
    case "hover-light":
      buttonClassName =
        "border-2 border-cred-100 bg-cred-100 text-white hover:bg-white hover:text-cred-100 hover:border-white";
      break;
    case "transparent":
      buttonClassName =
        "border-2 border-white bg-transparent text-white hover:bg-cred-100 hover:text-white hover:border-cred-100";
      break;
    case "transparent-black":
      buttonClassName = "bg-transparent text-black hover:bg-transparent";
      break;
    default:
      buttonClassName = "bg-cred-100 text-white hover:bg-gray-800";
  }
  switch (size) {
    case "sm":
      sizeClassName = "py-2 px-6 text-xs";
      break;
    default:
      sizeClassName = "py-3 px-6";
  }
  return (
    <a
      href={`tel:${phone.phone}`}
      className="hidden xl:flex font-poppins items-center gap-2 bg-cred-100 text-white font-medium px-6 py-3 rounded-lg border-2 border-cred-100 hover:bg-white hover:text-cred-100 hover:border-white"
    >
      {phone.label}
    </a>
  );
};

export default HeaderCallButton;
