import { Button } from "../common";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { BsArrowDown } from "react-icons/bs";
import Carousel from "nuka-carousel";
import { RefObject } from "react";

interface heroboxProps {
  scrollRef: RefObject<HTMLDivElement>;
}

const HeroBox = ({ scrollRef }: heroboxProps) => {
  const navigate = useNavigate();
  return (
    <Carousel autoplay slidesToShow={1} withoutControls wrapAround>
      <div className="relative bg-cover bg-bottom bg-fixed h-[80vh] md:h-[65vh] lg:h-screen pt-20">
        <div
          className="absolute inset-0 bg-cover bg-bottom grayscale"
          style={{ backgroundImage: `url(/images/slider3.png)` }}
        ></div>
        <div className="absolute inset-0 container flex items-center h-full mx-auto px-6 text-white">
          <div className="w-full md:w-2/3 lg:w-2/5">
            <div>
              <Fade direction="left" triggerOnce>
                <h1 className="text-4xl md:text-5xl font-bold mb-3">
                  Unlocking Dreams,
                </h1>
                <h1 className="text-4xl md:text-5xl font-bold mb-4">
                  One <span className="text-cred-100">Mortgage</span> at a Time
                </h1>
                <p className="text-xl font-semibold mb-6">
                  Where Every Mortgage Leads To Your Happy Home
                </p>
                <div className="flex flex-wrap items-center gap-3">
                  <Button
                    variant="hover-light"
                    className="flex items-center gap-2"
                    onClick={() => navigate("/apply-now")}
                  >
                    <span>Apply Now</span>
                  </Button>
                  <Button
                    variant="hover-light"
                    className="flex items-center gap-2"
                    onClick={() => navigate("/contact")}
                  >
                    <span>Consult Now</span>
                  </Button>
                </div>
              </Fade>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-6 text-center">
          <button
            className="bg-white rounded-full p-2 animate-bounce duration-700"
            onClick={() =>
              scrollRef.current?.scrollIntoView({ behavior: "smooth" })
            }
          >
            <BsArrowDown size={"20px"} />
          </button>
        </div>
      </div>
      <div className="relative bg-cover bg-bottom bg-fixed h-[80vh] md:h-[65vh] lg:h-screen pt-20">
        <div
          className="absolute inset-0 bg-cover bg-bottom grayscale"
          style={{ backgroundImage: `url(/images/slider2.png)` }}
        ></div>
        <div className="absolute inset-0 container flex items-center h-full mx-auto px-6">
          <div className="w-full md:w-2/3 lg:w-2/5">
            <div>
              <Fade direction="left" triggerOnce>
                <h1 className="text-4xl md:text-5xl font-bold mb-3">
                  Unlocking Dreams,
                </h1>
                <h1 className="text-4xl md:text-5xl font-bold mb-4">
                  One Mortgage at a Time
                </h1>
                <p className="text-xl font-semibold mb-6">
                  Where Every Mortgage Leads To Your Happy Home
                </p>
                <div className="flex flex-wrap items-center gap-3">
                  <Button
                    variant="hover-light"
                    className="flex items-center gap-2"
                    onClick={() => navigate("/apply-now")}
                  >
                    <span>Apply Now</span>
                  </Button>
                  <Button
                    variant="hover-light"
                    className="flex items-center gap-2"
                    onClick={() => navigate("/contact")}
                  >
                    <span>Consult Now</span>
                  </Button>
                </div>
              </Fade>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-6 text-center">
          <button
            className="bg-white rounded-full p-2 animate-bounce"
            onClick={() =>
              scrollRef.current?.scrollIntoView({ behavior: "smooth" })
            }
          >
            <BsArrowDown size={"20px"} />
          </button>
        </div>
      </div>
    </Carousel>
  );
};

export default HeroBox;
