import Herobox from "./Herobox";
import Services from "./Services";
import Testimonials from "./Testimonials";
import Calculator from "./calculator";
import Lenders from "./Lenders";
import FaqSection from "./FaqSection";
import ApplyForm from "./apply-form";
import { useRef } from "react";

export default function HomeScreen() {
  const scrollRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <Herobox scrollRef={scrollRef} />
      <Services scrollRef={scrollRef} />
      <Calculator />
      <FaqSection />
      <ApplyForm />
      <Testimonials />
      <Lenders />
    </>
  );
}
