import Team from "../common/team";
import AboutUs from "./AboutUs";
import CallToAction from "./CallToAction";
import FunFacts from "./FunFacts";

export default function AboutScreen() {
  return (
    <>
      <AboutUs />
      <FunFacts />
      <CallToAction />
      <Team />
    </>
  );
}
