import React from "react";
//Style
// import './App.scss';
//Importing Pages
import {
  HomePage,
  ContactPage,
  ResidentialPage,
  CommercialPage,
  AboutPage,
  BlogPage,
  SingleBlogPage,
  ApplyNowPage,
  Refinance,
  LandTransferTax,
  HelpPage,
  LenderPage,
  TestimonialPage,
  FaqPage,
  MortgagePage,
  NewsPage,
  BadCreditLoan,
  PrivateMortgages,
  RentalPropertyMortgages,
  BusinessLoan,
  BridgeFinancing,
  ConstructionLoan,
  InvestmentProperty,
  FirstHome,
  HELOC,
  ResidentialMortgage,
  DebtConsolidation,
  SingleNewsPage,
  MortgageCalculators,
} from "./pages";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import OurTeamPage from "./pages/OurTeamPage";
import { ToastContainer } from "react-toastify";
import { OpenFromTop } from "./components";
// import LenderPage from "./pages/LenderPage";

function App() {
  return (
    <>
      <Router>
        <OpenFromTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/news/:permalink" element={<SingleNewsPage />} />
          <Route path="/mortgagerates" element={<MortgagePage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/lender" element={<LenderPage />} />
          <Route path="/testimonial" element={<TestimonialPage />} />
          <Route path="/help/:id" element={<HelpPage />} />
          <Route path="/our-team" element={<OurTeamPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/residential" element={<ResidentialPage />} />
          <Route path="/commercial" element={<CommercialPage />} />
          <Route path="/blogs" element={<BlogPage />} />
          <Route path="/blogs/:permalink" element={<SingleBlogPage />} />
          <Route path="/apply-now" element={<ApplyNowPage />} />
          {/* <Route path="/refinance" element={<Refinance />} /> */}
          <Route path="/land-transfer-tax" element={<LandTransferTax />} />
          <Route path="/bad-credit-loan" element={<BadCreditLoan />} />
          <Route path="/private-mortgages" element={<PrivateMortgages />} />
          <Route
            path="/rental-property-mortgages"
            element={<RentalPropertyMortgages />}
          />
          <Route path="/business-loan" element={<BusinessLoan />} />
          <Route path="/bridge-financing" element={<BridgeFinancing />} />
          <Route path="/construction-loan" element={<ConstructionLoan />} />
          <Route path="/investment-property" element={<InvestmentProperty />} />
          <Route path="/first-home" element={<FirstHome />} />
          <Route path="/heloc" element={<HELOC />} />
          <Route path="/debt-consolidation" element={<DebtConsolidation />} />
          <Route path="/refinance" element={<Refinance />} />
          <Route
            path="/residential-mortgage"
            element={<ResidentialMortgage />}
          />
          <Route
            path="/mortgage-calculators/:permalink"
            element={<MortgageCalculators />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
