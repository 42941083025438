import axios, { BACKEND_URL } from "../axios";

class RatesModel {
  static endPoint = "rate/";

  static getRate() {
    return axios.get(BACKEND_URL + this.endPoint);
  }
}

export default RatesModel;
