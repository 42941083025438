import React from "react";

export default function ApplyThankYou() {
  return (
    <div className="flex flex-col items-center">
      <img src="/images/thankyou.png" alt="" className="w-56" />
      <h2 className="text-2xl font-bold text-center mb-4">
        Thank You For Applying!
      </h2>
    </div>
  );
}
