import { useNavigate } from "react-router-dom";
import { ASSETS_URL, BlogModel } from "../../service";
import Button from "./Button";

type BlogCardProps = {
  data: BlogModel;
};

const BlogCard = (props: BlogCardProps) => {
  const navigate = useNavigate();

  return (
    <div>
      <img
        src={`${
          props.data.image == "avatar.jpg"
            ? "/images/residential-bg.jpg"
            : ASSETS_URL + props.data.image
        }`}
        className="w-full h-80 object-cover object-center rounded-xl mb-3"
      />
      <p className="text-lg text-white font-bold mb-3 line-clamp-2">
        {props.data.title}
      </p>
      <Button
        variant="hover-light"
        onClick={() => navigate(`/blogs/${props.data.id}`)}
      >
        Read More
      </Button>
    </div>
  );
};

export default BlogCard;
