import { useNavigate } from "react-router-dom";
import Button  from "../common/Button";
import { Player } from "@lottiefiles/react-lottie-player";
import { Fade } from "react-awesome-reveal";
import { useEffect, useState } from "react";
import RatesModel from "../../service/models/RatesModel";

export default function CalculatorSection() {
  const navigate = useNavigate();
  const [rate, setRate] = useState();

  useEffect(() => {
    const fetchRate = async () => {
      try {
        let response = await RatesModel.getRate();
        setRate(response.data.data[0].rate);
      } catch (error) {
        console.log(error);
      }
    };
    fetchRate();
  }, []);

  return (
    <section>
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-10 lg:gap-28 py-16 lg:py-24">
          <Fade direction="left" delay={300} triggerOnce>
            <div>
              <div className="flex items-center gap-3 text-cred-100 mb-6">
                <span className="h-0.5 w-12 bg-cred-100" />
                <p className="font-bold">Find Best Rates</p>
              </div>
              <h3 className="text-4xl lg:text-5xl font-bold mb-6 w-full lg:w-2/3">
                Prime Rates From <span className="text-cred-100">{rate}%</span>
              </h3>
              <p className="text-base mb-6">
                Deltaf mortgage rates with other banks and lender. Check the
                latest rate using our mortgage rate calculator. All the rates
                are updated on the latest policies and terms.
              </p>
              <Button variant="primary" onClick={() => navigate("/apply-now")}>
                Get Your Best Rate
              </Button>
            </div>
          </Fade>
          <Player
            autoplay
            loop
            src="https://lottie.host/ce2660a1-4885-49ad-a2c2-bc4933260ac5/585zPFZv2S.json"
            style={{ width: "250px", height: "250px" }}
          />
        </div>
      </div>
    </section>
  );
}
