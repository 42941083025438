import { FormikHelpers, useFormik } from "formik";
import { contactValidation } from "../../utils/schemas";
import { Input } from "../common";
import { Textarea } from "../common";
import { Button } from "../common";

type formValues = {
  name: string;
  email: string;
  subject: string;
  message: string;
};

interface formProps {
  onFormSubmit: (
    values: formValues,
    formikHelpers: FormikHelpers<formValues>
  ) => void;
}

const ContactForm: React.FC<formProps> = ({ onFormSubmit }) => {
  const initialValues: formValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };
  const { values, handleChange, handleSubmit, errors, touched, isSubmitting } =
    useFormik({
      initialValues: initialValues,
      validationSchema: contactValidation,
      onSubmit: onFormSubmit,
    });
  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-3">
        <Input
          type="text"
          name="name"
          placeholder="Enter Name"
          value={values.name}
          onChange={handleChange}
          errors={errors.name}
          touched={touched.name}
          readOnly={isSubmitting}
        />
        <Input
          type="email"
          name="email"
          placeholder="Enter Email"
          value={values.email}
          onChange={handleChange}
          errors={errors.email}
          touched={touched.email}
          readOnly={isSubmitting}
        />
      </div>
      <div className="mb-6">
        <Input
          type="text"
          name="subject"
          placeholder="Enter Subject"
          value={values.subject}
          onChange={handleChange}
          errors={errors.subject}
          touched={touched.subject}
          readOnly={isSubmitting}
        />
      </div>
      <div className="mb-5">
        <Textarea
          rows={4}
          name="message"
          placeholder="Enter Message"
          value={values.message}
          onChange={handleChange}
          errors={errors.message}
          touched={touched.message}
          readOnly={isSubmitting}
        />
      </div>
      <Button
        type="submit"
        variant="hover-light"
        fullWidth={true}
        isDisabled={isSubmitting}
      >
        Send Message
      </Button>
    </form>
  );
};

export default ContactForm;
