import React from "react";

type optionObj = {
  label: string;
  value: string;
};

interface SelectProps {
  label?: string;
  type?: string;
  name: string;
  placeholder?: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: any;
  errors?: any;
  touched?: any;
  options: optionObj[];
  labelClass?: string;
}

const CSelect: React.FC<SelectProps> = ({
  label,
  options,
  errors,
  touched,
  className,
  labelClass,
  ...selectProps
}) => {
  return (
    <div className="my-3">
      {label && (
        <label className={`block font-bold mb-2 ${labelClass}`}>{label}</label>
      )}
      <select
        className={`w-full rounded border border-gray-300 focus:border-2 focus:border-gray-400 p-2 ${className}`}
        {...selectProps}
      >
        {options.map((item, i) => {
          return (
            <option key={i} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </select>
      {errors && touched && (
        <p className="text-sm text-red-500 mt-2">{errors}</p>
      )}
    </div>
  );
};

export default CSelect;
