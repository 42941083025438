import { CallToAction } from "../common";
import AboutResidential from "./AboutResidential";
import ResidentialTypes from "./ResidentialTypes";

export default function ResidentialScreen() {
  return (
    <>
      <AboutResidential />
      <ResidentialTypes />
    </>
  );
}
