import { Fade } from "react-awesome-reveal";
import GetInTouchSection from "../GetInTouchSection";
import AffordabilityCalculator from "./AffordabilityCalculator";

export default function BadCreditLoanScreen() {
  return (
    <>
      <section className="px-6 py-16 lg:py-24">
        <div className="container mx-auto">
          <Fade direction="up" triggerOnce>
            <h2 className="text-4xl lg:text-5xl mb-4">Bad Credit Loan</h2>
            <p className="mb-10">
              Don’t let credit challenges stop you. We offer loan solutions that
              accommodate diverse credit histories, helping you secure the
              financing you need.
              <br />
              <br />
              Our Bad Credit Loan services are designed to help you navigate the
              challenges of securing a mortgage with less-than-perfect credit.
              We provide advice on improving your credit score and offer
              alternative lending solutions to help you get on the path to
              homeownership.
            </p>
          </Fade>
          <Fade direction="up" triggerOnce>
            <AffordabilityCalculator />
          </Fade>
        </div>
      </section>
      <GetInTouchSection />
    </>
  );
}
