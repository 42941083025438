import { useParams } from "react-router-dom";
import { HelpScreen, Loader, TitleSection } from "../components";
import { DefaultLayout } from "../layouts";
import { HelpModel } from "../service";
import { useEffect, useState } from "react";

function HelpPage() {
  let { id } = useParams<{ id: string }>();

  const [help, setHelp] = useState<HelpModel>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Define the effect function directly inside useEffect
    const fetchHelp = async () => {
      try {
        setIsLoading(true);
        let response: any = await HelpModel.get();
        let myHelps = await HelpModel.fromResponseToHelpList(response.data.data);

        // Find the help object with the new ID
        const newHelp = myHelps.find(obj => obj.id === id);

        // Update the state with the new help object
        if (newHelp) {
          setHelp(newHelp);
        } else {
          // Handle case when help with the new ID is not found
          setHelp(undefined);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHelp(); // Call the function to fetch help
  }, [id]);

  return (
    <DefaultLayout>
      <TitleSection title={isLoading ? "Loading..." : (help?.title || "Title")} bg="/images/residential-bg.jpg" />
      {isLoading || !help ? (
        <Loader />
      ) : (
        <HelpScreen model={help} />
      )}
    </DefaultLayout>
  );
}

export default HelpPage;
