import { MdHomeFilled } from "react-icons/md";
import { DefaultLayout } from "../layouts";
import { TitleSection, MortgageCalculatorsScreen } from "../components";
import { Navigate, useParams } from "react-router-dom";

export default function MortgageCalculators() {
  const params = useParams();
  const reqPermalink: string | undefined = params.permalink;
  if (reqPermalink === undefined) {
    return <Navigate to="/" />;
  }

  const breadCrumbs = [
    { title: <MdHomeFilled />, link: "/" },
    { title: "Mortgage Calculators", link: "#" },
    {
      title: reqPermalink
        .toLowerCase()
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join(" "),
      link: "#",
    },
  ];

  return (
    <DefaultLayout>
      <TitleSection
        title="Mortgage Calculators"
        bg="/images/bad-credit-loan-bg.png"
        breadCrumbs={breadCrumbs}
      />
      <MortgageCalculatorsScreen permalink={reqPermalink} />
    </DefaultLayout>
  );
}
