import { useState, useEffect } from "react";
import { BlogCard, Loader, NewsCard } from "../common";
import { BlogModel } from "../../service";
import { Fade } from "react-awesome-reveal";

export default function BlogScreen() {
  const [blogsList, setBlogsList] = useState<BlogModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        let response: any = await BlogModel.get();
        let blogs = await BlogModel.fromResponseToBlogList(response.data.data);
        setBlogsList(blogs);
      } catch (error) {
        console.log("Error fetching blogs:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBlogs();
  }, []);

  return (
    <div className=" bg-neutral-900 py-16 md:py-24 px-6">
      <div className="container mx-auto">
        {isLoading ? (
          <Loader />
        ) : blogsList.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 py-5">
            <Fade direction="up" triggerOnce>
              {blogsList.map((item, i) => {
                return <BlogCard key={i} data={item} />;
              })}
            </Fade>
          </div>
        ) : (
          <p className="text-center">No blogs found!</p>
        )}
      </div>
    </div>
  );
}
