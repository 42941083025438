import { useState, useEffect } from "react";
import { Loader, NewsCard } from "../common";
import { NewsModel } from "../../service";
import { Fade } from "react-awesome-reveal";

type NewsItem = {
  _id: string;
  heading: string;
  description: string;
  image: string;
};

export default function NewsScreen() {
  const [newsList, setNewsList] = useState<NewsItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        let response = await NewsModel.getNews();
        setNewsList(response.data.data);
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchNews();
  }, []);

  return (
    <div className=" bg-neutral-900 py-16 md:py-24 px-6">
      <div className="container mx-auto">
        {isLoading ? (
          <Loader />
        ) : newsList.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 py-5">
            <Fade direction="up" triggerOnce>
              {newsList.map((item, i) => {
                return <NewsCard key={i} data={item} />;
              })}
            </Fade>
          </div>
        ) : (
          <p className="text-center">No news found!</p>
        )}
      </div>
    </div>
  );
}
