import axios, { BACKEND_URL } from "../axios";

class NewsModel {
  static endPoint = "news/";

  static getNews() {
    return axios.get(BACKEND_URL + this.endPoint);
  }
}

export default NewsModel;
