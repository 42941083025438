import axios, { BACKEND_URL } from "../axios";

class TestimonialModel {
  static endPoint = "testimonials/";

  static registerMessage(body: any) {
    return axios.post(BACKEND_URL + this.endPoint + "testimonialsGetAll", body);
  }

  static get(url: String) {
    return axios.get(BACKEND_URL + this.endPoint + url);
  }
}

export default TestimonialModel;
