import { DefaultLayout } from "../../layouts";
import { Button, Input, TitleSection } from "../../components";
import React from "react";

export default function LandTransferTax() {
  const [monthly, setMonthly] = React.useState<number>(0);
  const [portion, setPortion] = React.useState<number>(0);
  const [newBalance, setNewBalance] = React.useState<number>(0);
  const [balance, setBalance] = React.useState<number>(0);
  const [interest, setInterest] = React.useState<number>(0);
  const [year, setYear] = React.useState<number>(0);


  function calculateValues() {
    const balance = 100000;
    const interest = 6;
    const year = 30;

    const monthlyVal = calculateMonthlyPayment(balance, year, interest);
    const portionVal = calPortionPrinciple(monthlyVal, balance, interest);
    const newBalanceVal = calNewBalance(portionVal, balance);

    setMonthly(monthlyVal);
    setPortion(portionVal);
    setNewBalance(newBalanceVal);
  }

  function calculateMonthlyPayment(
    salePrice: number,
    loanLength: number,
    yearlyInterest: number
  ): number {
    let monthlyInterest = yearlyInterest / 12 / 100;
    let numPayments = loanLength * 12;
    let powerVal = Math.pow(1 + monthlyInterest, numPayments);
    let numerator = salePrice * monthlyInterest * powerVal;
    let denominator = Math.pow(1 + monthlyInterest, numPayments) - 1;
    let monthlyPayment = numerator / denominator;
    let total = Number(monthlyPayment.toFixed(2));
    return total;
  }

  function calPortionPrinciple(
    monthly: number,
    price: number,
    interest: number
  ): number {
    let calInterest = interest / 12;
    let value = price * (calInterest / 100);
    let total = monthly - value;
    return Number(total.toFixed(2));
  }

  function calNewBalance(portion: number, price: number): number {
    return price - portion;
  }

  return (
    <DefaultLayout>
      <TitleSection
        title="Prepayment Charge Calculator"
        bg="/images/calculator-bg.jpg"
      />
      <div className="container mx-auto px-6 my-10">
        <div className="grid grid-cols-1 w-full lg:w-2/3 mx-auto">
          <div className="bg-neutral-50 p-3">
            <h5 className="text-2xl font-bold mb-3 bg-slate-300">
              Mortgage Prepayment Charge Details
            </h5>
            
            <div className="border mb-3 px-5 py-2">
              <div className="flex flex-col md:flex-row items-end gap-3 my-2">
                <div className="w-full md:w-1/2">
                  <Input 
                  type="number" 
                  label=" Loan:($)" 
                  name="price" 
                  value={balance}
                  onChange={(e) => setBalance(parseInt(e.target.value))}
                  />
                </div>
                <div className="w-full md:w-1/2">
                  <Input
                   type="number" 
                   label="Rate %:" 
                   name="price"
                   value={interest}
                   onChange={(e) => setInterest(parseInt(e.target.value))}
                   />
                </div>
              </div>

              <div className="flex flex-col md:flex-row items-end gap-3 my-2">
                <div className="w-full md:w-1/2">
                  <Input
                    type="number"
                    label="Amortization Period:"
                    name="price"
                    value={year}
                    onChange={(e) => setYear(parseInt(e.target.value))}
                  />
                </div>
              </div>
             
              <div className="flex items-end gap-3 my-2 justify-between">
                
                <Button
                  onClick={() => {
                    calculateValues();
                  }}
                >
                  Calculate
                </Button>
              </div>
            </div>
            <div className="border px-5">
              <h6 className="text-2xl font-bold mb-3 bg-slate-300">
                Your Results:
              </h6>
              <p className="items-between">Prepayment Charge Details</p>
              <div className="flex items-between border-t">
                <p className="w-11/12 font-semibold">
                  The Monthly Interest for This Loan is:
                </p>
                <p className="w-1/12 text-1xl font-semibold"> ${monthly}</p>
              </div>
              <div className="flex items-center ">
                <p className="w-11/12 font-semibold">
                  Portion of Principle Paid Off:
                </p>
                <p className="w-1/12 text-1xl font-semibold"> ${portion}</p>
              </div>
              <div className="flex items-center">
                <p className="w-11/12 font-semibold">
                  New Balance of the First Month: 
                </p>
                <p className="w-1/12 text-1xl font-semibold"> ${newBalance}</p>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
