import { Fade } from "react-awesome-reveal";
import AffordabilityCalculator from "../services/bad-credit-loan-screen/AffordabilityCalculator";
import PrepaymentChargeCalculator from "../services/private-mortgages-screen/PrepaymentChargeCalculator";
import HELOCLimitCalculator from "../services/heloc-screen/HELOCLimitCalculator";
import DebtConsolidationCalculator from "../services/debt-consolidation-screen/DebtConsolidationCalculator";
import RefinanceCalculator from "../services/refinance-screen/RefinanceCalculator";

interface functionProps {
  permalink: string;
}

export default function MortgageCalculatorsScreen({
  permalink,
}: functionProps) {
  const getCalculator = () => {
    switch (permalink) {
      case "affordability":
        return <AffordabilityCalculator />;
      case "monthly-payment":
        return <PrepaymentChargeCalculator />;
      case "heloc":
        return <HELOCLimitCalculator />;
      case "debt-consolidation":
        return <DebtConsolidationCalculator />;
      case "refinance":
        return <RefinanceCalculator />;
      default:
        return <></>;
    }
  };

  return (
    <section className="px-6 py-16 lg:py-24">
      <div className="container mx-auto">
        <div className="flex flex-col gap-10">
          <Fade direction="up" triggerOnce>
            {getCalculator()}
          </Fade>
        </div>
      </div>
    </section>
  );
}
