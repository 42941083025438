import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Loader } from "../common";
import { ASSETS_URL, NewsModel } from "../../service";
import { Fade } from "react-awesome-reveal";
import { FaChevronRight } from "react-icons/fa";
import { MdHomeFilled } from "react-icons/md";

type SingleNewsProps = {
  id: string;
};

type NewsItem = {
  _id: string;
  heading: string;
  description: string;
  image: string;
};

export default function SingleNewsScreen(props: SingleNewsProps) {
  const [news, setNews] = useState<NewsItem>({
    _id: "",
    heading: "",
    description: "",
    image: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [breadCrumbs, setBreadCrumbs] = useState([
    { title: <MdHomeFilled />, link: "/" },
    { title: "News", link: "/news" },
  ]);

  useEffect(() => {
    const getNews = async () => {
      try {
        let response = await NewsModel.getNews();
        let result = response.data.data;
        let item = result.find((obj: NewsItem) => obj._id === props.id);
        if (item) {
          setNews(item);
          setBreadCrumbs([...breadCrumbs, { title: item.heading, link: "#" }]);
        }
      } catch (error) {
        console.log(error);
        navigate("/");
      } finally {
        setIsLoading(false);
      }
    };
    getNews();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="container mx-auto px-6 py-16">
            <Fade triggerOnce>
              <div className="flex flex-wrap items-center gap-1 mb-5 pt-14">
                {breadCrumbs &&
                  breadCrumbs.map((item, i) => {
                    if (i < breadCrumbs.length - 1) {
                      return (
                        <>
                          <NavLink
                            key={i}
                            to={item.link}
                            className={i === 0 ? "text-lg" : "text-sm"}
                          >
                            {item.title}
                          </NavLink>
                          <FaChevronRight fontSize="12px" />
                        </>
                      );
                    } else {
                      return (
                        <NavLink key={i} to={item.link} className="text-sm">
                          {item.title}
                        </NavLink>
                      );
                    }
                  })}
              </div>
              <img
                src={
                  news?.image == "avatar.jpg" || !news.image
                    ? "/images/residential-bg.jpg"
                    : ASSETS_URL + news?.image
                }
                className="w-full h-60 md:h-96 object-cover object-center rounded-xl mb-5"
              />
              <p className="text-3xl font-bold lg:text-4xl mb-7">
                {news?.heading}
              </p>
              <div
                className="blog-content"
                dangerouslySetInnerHTML={{ __html: news?.description }}
              />
            </Fade>
          </div>
        </>
      )}
    </>
  );
}
