import React from "react";

interface InputProps {
  label?: string;
  labelLinkText?: string;
  labelLink?: string;
  type?: string;
  name: string;
  placeholder?: string;
  className?: string;
  labelClass?: string;
  parentClass?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  errors?: any;
  touched?: any;
  autoFocus?: boolean;
  readOnly?: boolean;
  min?: number;
  max?: number;
  step?: number;
  leadingIcon?: string;
  endingIcon?: string;
}

const Input: React.FC<InputProps> = ({
  label,
  labelLinkText,
  labelLink,
  type = "text",
  className,
  labelClass,
  parentClass,
  errors,
  touched,
  leadingIcon,
  endingIcon,
  ...inputProps
}) => {
  return (
    <div className={`my-3 ${parentClass}`}>
      {label && (
        <div className="flex flex-wrap gap-2 items-center">
          <label className={`block font-bold mb-2 ${labelClass}`}>
            {label}
          </label>
          {labelLink && (
            <a href={labelLink} className="text-blue-800 font-bold mb-2">
              {labelLinkText}
            </a>
          )}
        </div>
      )}
      <div className="relative">
        <input
          className={`w-full rounded border border-gray-300 focus:outline-gray-400 p-2 ${className}`}
          type={type}
          {...inputProps}
        />
        {leadingIcon && <span className="absolute top-2 left-3">{leadingIcon}</span>}
        {endingIcon && <span className="absolute top-2 right-3">{endingIcon}</span>}
      </div>

      {errors && touched && (
        <p className="text-sm text-red-500 mt-2">{errors}</p>
      )}
    </div>
  );
};

export default Input;
