import { Player } from "@lottiefiles/react-lottie-player";
import { Button } from "../common";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { PagesModel } from "../../service";
import { Fade } from "react-awesome-reveal";

const AboutResidential = () => {
  const navigate = useNavigate();
  const [aboutText, setAboutText] = useState(
    `The first step to becoming a homeowner is deciding to buy your dream home rather than renting a residence. The majority of individuals, if not all, may find it challenging to obtain a mortgage, particularly if they are not familiar with the most recent developments in the mortgage market, such as changes in rates and other factors. Obtaining mortgages for your property is not any easier because there are numerous mortgage solutions available. We at Delta Mortgages will advise and direct you on the best products to meet your needs. Either you want mortgage renewal or are a first-time home buyer, you are at the right place!`
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await PagesModel.get("residential");
        setAboutText(response.data.data.description);
      } catch (error: any) {
        console.log("Error while fetching the data:", error.message);
      }
    };
    fetchData();
  });
  return (
    <div className="container mx-auto px-6 py-16">
      <Fade direction="up" triggerOnce>
        <h2 className="text-4xl font-bold text-center mb-6">
          Best Residential Mortgage Solutions
        </h2>
      </Fade>
      <Fade direction="up" delay={300} triggerOnce>
        <div
          style={{ backgroundColor: "white" }}
          className="grid grid-cols-1 lg:grid-cols-2 gap-8 bg-blue-50 rounded-lg p-8 w-full lg:w-4/5 mx-auto"
        >
          <div>
            <p className="text-gray-600 mb-3">{aboutText}</p>
            <Button onClick={() => navigate("/contact")}>Contact Now</Button>
          </div>
          <Player
            autoplay
            loop
            src="https://assets4.lottiefiles.com/packages/lf20_tnn8kkjc.json"
            style={{ width: "250px", height: "250px" }}
          />
        </div>
      </Fade>
    </div>
  );
};

export default AboutResidential;
