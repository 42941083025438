import { Fade } from "react-awesome-reveal";
import Carousel from "nuka-carousel";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useEffect, useState } from "react";

const Lenders = () => {
  const width = useWindowWidth();
  const [noOfSlides, setNoOfSlides] = useState(4);

  useEffect(() => {
    if (width < 768) {
      setNoOfSlides(1);
    } else if (width < 1024) {
      setNoOfSlides(3);
    } else {
      setNoOfSlides(4);
    }
  }, [width]);

  const renderHeading = () => (
    <Fade direction="up" triggerOnce>
      <h2 className="text-4xl lg:text-5xl mb-8">Lenders</h2>
    </Fade>
  );

  const renderCarousel = () => (
    <Fade delay={300} triggerOnce>
      <Carousel
        defaultControlsConfig={{
          nextButtonText: <FaArrowRight />,
          nextButtonStyle: {
            backgroundColor: "#fe0000",
            borderRadius: "50px",
            position: "absolute",
            top: "-75px",
            right: 0,
          },
          prevButtonText: <FaArrowLeft />,
          prevButtonStyle: {
            backgroundColor: "#fe0000",
            borderRadius: "50px",
            position: "absolute",
            top: "-75px",
            right: "45px",
          },
          pagingDotsStyle: { display: "none" },
        }}
        autoplay
        autoplayInterval={3000}
        wrapAround
        slidesToShow={noOfSlides}
      >
        <div className="w-auto h-full flex justify-center items-center hover:scale-95 transition-transform ease-in-out">
          <img src="/images/SB-logo.png" alt="" className="w-24" />
        </div>
        <div className="w-auto h-full flex justify-center items-center hover:scale-95 transition-transform ease-in-out">
          <img src="/images/magenta-logo.png" alt="" className="w-40" />
        </div>
        <div className="w-auto h-full flex justify-center items-center hover:scale-95 transition-transform ease-in-out">
          <img src="/images/EB-logo.png" alt="" className="w-40" />
        </div>
        <div className="w-auto h-full flex justify-center items-center hover:scale-95 transition-transform ease-in-out">
          <img src="/images/HB-logo.png" alt="" className="w-40" />
        </div>
        <div className="w-auto h-full flex justify-center items-center hover:scale-95 transition-transform ease-in-out">
          <img src="/images/oppono.png" alt="" className="w-40" />
        </div>
        <div className="w-auto h-full flex justify-center items-center hover:scale-95 transition-transform ease-in-out">
          <img src="/images/fn.png" alt="" className="w-40" />
        </div>
        <div className="w-auto h-full flex justify-center items-center hover:scale-95 transition-transform ease-in-out">
          <img src="/images/gink.png" alt="" className="w-40" />
        </div>
        <div className="w-auto h-full flex justify-center items-center hover:scale-95 transition-transform ease-in-out">
          <img src="/images/vwr.png" alt="" className="w-28" />
        </div>
        <div className="w-auto h-full flex justify-center items-center hover:scale-95 transition-transform ease-in-out">
          <img src="/images/SBI-logo.png" alt="" className="w-24" />
        </div>
      </Carousel>
    </Fade>
  );

  return (
    <section className="bg-[#f4f4f4d1] py-16">
      <div className="container mx-auto px-6">
        {renderHeading()}
        <div className="">{renderCarousel()}</div>
      </div>
    </section>
  );
};

export default Lenders;
