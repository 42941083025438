import { MdHomeFilled } from "react-icons/md";
import { DefaultLayout } from "../../layouts";
import { HELOCScreen, TitleSection } from "../../components";

export default function HELOC() {
  const breadCrumbs = [
    { title: <MdHomeFilled />, link: "/" },
    { title: "Services", link: "#" },
    { title: "HELOC", link: "#" },
  ];

  return (
    <DefaultLayout>
      <TitleSection
        title="Services"
        bg="/images/bad-credit-loan-bg.png"
        breadCrumbs={breadCrumbs}
      />
      <HELOCScreen />
    </DefaultLayout>
  );
}
