import { Player } from "@lottiefiles/react-lottie-player";
import { Button } from "../common";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { PagesModel } from "../../service";
import { Fade } from "react-awesome-reveal";

const AboutCommercial = () => {
  const navigate = useNavigate();
  const [aboutText, setAboutText] = useState(
    `We at Delta Mortgages will pair you with commercial mortgage options that provide the best rates and are ideal for your company. To satisfy everyone's needs, the mortgage industry offers a variety of services. If it's Commercial Mortgage Renewal, Bridge Financing, Commercial Mortgages for Industrial Properties, or anything else, there are plenty of options. You can speak with our team of mortgage specialists to learn more about every service and to get advice on what your company requires.`
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await PagesModel.get("commercial");
        setAboutText(response.data.data.description);
      } catch (error: any) {
        console.log("Error while fetching the data:", error.message);
      }
    };
    fetchData();
  });
  return (
    <div className="container mx-auto px-6 py-16">
      <Fade direction="up" triggerOnce>
        <h2 className="text-4xl font-bold text-center mb-6">
          Best Commercial Mortgage Solutions
        </h2>
      </Fade>
      <Fade direction="up" delay={300} triggerOnce>
        <div style={{backgroundColor: 'white'}} className="grid grid-cols-1 lg:grid-cols-2 gap-8 bg-blue-50 rounded-lg p-8 w-full lg:w-4/5 mx-auto">
          <div>
            <p className="text-gray-600 mb-3">{aboutText}</p>
            <Button onClick={() => navigate("/contact")}>Contact Now</Button>
          </div>
          <Player
            autoplay
            loop
            src="https://assets7.lottiefiles.com/packages/lf20_aEtcq1Z1up.json"
            style={{ width: "250px", height: "250px" }}
          />
        </div>
      </Fade>
    </div>
  );
};

export default AboutCommercial;
