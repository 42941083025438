import React, { useState, useRef, useEffect } from "react";
import {
  FaPhoneAlt,
  FaChevronDown,
  FaTimes,
  FaChevronRight,
  FaRegFileAlt,
} from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Button } from "../../components";
import { Fade } from "react-awesome-reveal";
import HeaderCallButton from "../../components/common/HeaderCallButton";

const navItems: MenuItem[] = [
  {
    label: "Home",
    link: "/",
  },
  {
    label: "About",
    link: "/about",
  },
  {
    label: "Services",
    link: "#",
    subMenu: [
      { label: "Bad Credit Loan", link: "/bad-credit-loan" },
      { label: "Private Mortgages", link: "/private-mortgages" },
      {
        label: "Rental Property Mortgages",
        link: "/rental-property-mortgages",
      },
      { label: "Business Loan", link: "/business-loan" },
      { label: "Bridge Financing", link: "/bridge-financing" },
      { label: "Construction Loan", link: "/construction-loan" },
      { label: "Investment Property", link: "/investment-property" },
      { label: "First Home", link: "/first-home" },
      { label: "HELOC", link: "/heloc" },
      { label: "Debt Consolidation", link: "/debt-consolidation" },
      { label: "Refinance", link: "/refinance" },
      { label: "Residential Mortgage", link: "/residential-mortgage" },
    ],
  },
  {
    label: "Mortgage Calculators",
    link: "#",
    subMenu: [
      { label: "Affordability", link: "/mortgage-calculators/affordability" },
      {
        label: "Monthly Payment",
        link: "/mortgage-calculators/monthly-payment",
      },
      { label: "HELOC", link: "/mortgage-calculators/heloc" },
      {
        label: "Debt Consolidation",
        link: "/mortgage-calculators/debt-consolidation",
      },
      { label: "Refinance", link: "/mortgage-calculators/refinance" },
    ],
  },
  {
    label: "News",
    link: "/news",
  },
  {
    label: "Blogs",
    link: "/blogs",
  },
  {
    label: "Contact Us",
    link: "/contact",
  },
];
const phone = {
  phone: "905-216-0061",
  label: "905-216-0061",
};

interface MenuItem {
  label: string;
  link: string;
  subMenu?: {
    label: string;
    link: string;
  }[];
}

interface NavDropDownProps {
  menu: MenuItem;
  sticky: boolean;
}

const NavDropDown: React.FC<NavDropDownProps> = ({ menu, sticky }) => {
  const [show, setShow] = React.useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setShow(!show);
  };

  useEffect(() => {
    const onClickOutsideMenu = (e: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
        setShow(false);
      }
    };

    document.addEventListener("click", onClickOutsideMenu);

    return () => {
      document.removeEventListener("click", onClickOutsideMenu);
    };
  }, [show]);

  return (
    <div className="relative inline-block text-left" ref={menuRef}>
      <button
        type="button"
        className={`text-white flex items-center gap-2 font-medium pb-1 px-1 border-b-2 border-transparent hover:border-cred-100`}
        onClick={() => setShow(!show)}
      >
        {menu.label}
        <FaChevronDown className="inline-block" />
      </button>
      {show && (
        <div className="absolute z-10 mt-2 py-2 w-48 bg-white rounded-lg shadow-lg">
          {menu.subMenu?.map((item, i) => (
            <NavLink
              key={i}
              to={item.link}
              onClick={handleClick}
              className="dropdown-nav-link block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-cred-100"
            >
              {item.label}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

const MobileNav: React.FC = () => {
  const [isHidden, setIsHidden] = useState(true);
  const toggleMenu = () => setIsHidden(!isHidden);
  const navigate = useNavigate();
  return (
    <>
      <button
        className="block xl:hidden text-cred-100 border-2 border-cred-100 p-2 rounded-md"
        onClick={toggleMenu}
      >
        <FiMenu size={25} />
      </button>
      <div
        className={`xl:hidden fixed inset-0 w-full h-screen overflow-auto z-20 bg-neutral-900 p-5 ${
          isHidden ? "hidden" : "block"
        }`}
      >
        <div className="flex justify-between">
          <a href="/" className="flex items-center gap-3">
            <img src="/images/logo2.png" alt="logo" className="w-16" />
            <div>
              <p className="text-2xl font-bold text-white">DELTA</p>
              <p className="text-md font-semibold mb-0 text-white">
                MORTGAGES INC.
              </p>
            </div>
          </a>
          <button onClick={toggleMenu}>
            <FaTimes size={25} color="white" />
          </button>
        </div>
        <div className="flex flex-col gap-5 my-5">
          {navItems.map((item, i) => {
            if (item?.subMenu) {
              return (
                <div key={i} className="flex flex-col gap-5">
                  {item.subMenu.map((subItem, i) => {
                    return (
                      <NavLink
                        key={i}
                        to={subItem.link}
                        className="mob-nav-link text-lg text-white hover:text-cred-100 font-semibold"
                      >
                        <FaChevronRight className="inline" /> {subItem.label}
                      </NavLink>
                    );
                  })}
                </div>
              );
            }
            return (
              <NavLink
                key={i}
                to={item.link}
                className="mob-nav-link text-lg text-white hover:text-cred-100 font-semibold"
              >
                <FaChevronRight className="inline" /> {item.label}
              </NavLink>
            );
          })}
        </div>
        <Button
          variant="hover-light"
          className="flex items-center gap-2 mb-3"
          onClick={() => navigate("/apply-now")}
        >
          <FaRegFileAlt size={20} />
          <span>Apply Now</span>
        </Button>
        <a
          href={`tel:${phone.phone}`}
          className="flex items-center gap-2 w-fit bg-cred-100 text-white font-semibold px-4 py-3 border-2 border-cred-100 hover:bg-cred-200 hover:border-cred-200"
        >
          <FaPhoneAlt />
          {phone.label}
        </a>
      </div>
    </>
  );
};

interface HeaderProps {
  showApplyNowButton?: boolean;
}
const Header: React.FC<HeaderProps> = ({ showApplyNowButton = true }) => {
  const navigate = useNavigate();
  const [sticky, setSticky] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const onLinkClicked = () => {};

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`${
        sticky ? "fixed shadow" : "absolute"
      } bg-neutral-900 py-4 inset-x-0 z-20`}
    >
      <div className="container mx-auto flex justify-between items-center px-6">
        <Fade triggerOnce>
          {/* <Link to="/" className="flex items-center gap-3">
            <img src="/images/logo2.png" alt="Logo" className="w-16" />
            <div>
              <p className={`text-white text-2xl font-bold`}>DELTA</p>
              <p className={`text-white text-md font-semibold mb-0`}>
                MORTGAGES INC.
              </p>
              <p className={`text-white text-md font-semibold mb-0`}>
              License No 13078
              </p>
            </div>
            
          </Link> */}
          <Link to="/" className="flex items-center gap-3">
  <img src="/images/logo2.png" alt="Logo" className="w-16" />
  <div>
    <p className="text-white text-2xl font-bold">DELTA</p>
    <p className="text-white text-md font-semibold mb-0">
      MORTGAGES INC.
    </p>
    <p
      className="text-white text-sm font-medium mt-2 px-2 py-1 rounded-md"
      style={{ display: "inline-block", marginTop: "4px" }}
    >
      License No: 13078
    </p>
  </div>
</Link>

          <nav className="hidden xl:flex items-center gap-x-5">
            {navItems.map((item, i) => {
              if (item?.subMenu) {
                return <NavDropDown key={i} menu={item} sticky={sticky} />;
              }
              return (
                <NavLink
                  key={i}
                  to={item.link}
                  className={`nav-link text-white font-medium pb-1 px-1 border-b border-transparent hover:border-cred-100 hover:text-cred-100`}
                >
                  {item.label}
                </NavLink>
              );
            })}
          </nav>
          <div className="flex items-center gap-2">
            {/* {showApplyNowButton && (
              <Button
                variant="hover-light"
                className="hidden xl:flex items-center gap-2"
                onClick={() => navigate("/apply-now")}
              >
                <FaRegFileAlt size={20} />
                <span>Apply Now</span>
              </Button>
            )} */}
            <HeaderCallButton />
            {/*<a*/}
            {/*  href={`tel:${phone.phone}`}*/}
            {/*  className="hidden xl:flex font-poppins items-center gap-2 bg-cred-100 text-white font-medium px-4 py-3 rounded-lg border-2 border-cred-100 hover:bg-white hover:text-cred-100 hover:border-white"*/}
            {/*>*/}
            {/*  <FaPhoneAlt />*/}
            {/*  {phone.label}*/}
            {/*</a>*/}
          </div>
        </Fade>
        <MobileNav />
      </div>
    </header>
  );
};

export default Header;
