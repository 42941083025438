import { useEffect, useState } from "react";

function useWindowWidth() {
    const [width, setWidth] = useState<number>(window.innerWidth);
    
    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    });
    
    return width;
}

export default useWindowWidth;