import axios, { BACKEND_URL } from "../axios";

class BlogModel {
    id: string = "";
    title: string = "";
    description: string = "";
    image: string = "";

    constructor(
        id: string,
        title: string,
        description: string,
        image: string
    ) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.image = image;
    }

    static endPoint: string = "blog";

    static get(): any {
        return axios.get(BACKEND_URL + this.endPoint);
    }

    static async fromResponseToBlogList(data: any): Promise<BlogModel[]> {
        const blogs: BlogModel[] = data.map((item: any) => {
          return new BlogModel(item._id, item.title, item.description, item.image); // Use 'item' instead of 'data'
        });
        return blogs;
    }
      

    static async getBlog(id: string) {
        return axios.get(BACKEND_URL + this.endPoint + "/" + id);
    }

}

export default BlogModel;
