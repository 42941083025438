import { ContactForm } from "../common";
import { FormikHelpers } from "formik";
import { MessageModel } from "../../service";
import { toast } from "react-toastify";
import { Fade } from "react-awesome-reveal";

type formValues = {
  name: string;
  email: string;
  subject: string;
  message: string;
};

export default function GetInTouchSection() {
  const onFormSubmit = async (
    values: formValues,
    formikHelpers: FormikHelpers<formValues>
  ) => {
    try {
      let response = await MessageModel.registerMessage(values);
      if (response.status == 200) {
        toast.success("Message Submitted!");
        formikHelpers.resetForm();
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  return (
    <section
      className="bg-cover bg-center bg-no-repeat px-6 py-16 lg:py-24"
      style={{ backgroundImage: "url('/images/contact-section-bg.png')" }}
    >
      <div className="container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
          <Fade direction="up" triggerOnce>
            <div>
              <h2 className="text-3xl lg:text-4xl text-white mb-3">
                Get In Touch
              </h2>
              <p className="text-white">
                Whether you have a quesiton, feedback or need assistance, our
                team is here to help.
              </p>
            </div>
          </Fade>
          <Fade direction="up" triggerOnce>
            <ContactForm onFormSubmit={onFormSubmit} />
          </Fade>
        </div>
      </div>
    </section>
  );
}
