import ContactForm from "./ContactForm";
import Map from "./Map";
import { toast } from "react-toastify";
import { MessageModel } from "../../service";
import { FormikHelpers } from "formik";
import { Fade } from "react-awesome-reveal";
import { FiPhone, FiPrinter, FiMail, FiMapPin } from "react-icons/fi";

type formValues = {
  name: string;
  email: string;
  subject: string;
  message: string;
};

const ContactScreen = () => {
  const onFormSubmit = async (
    values: formValues,
    formikHelpers: FormikHelpers<formValues>
  ) => {
    try {
      let response = await MessageModel.registerMessage(values);
      if (response.status == 200) {
        toast.success("Message Submitted!");
        formikHelpers.resetForm();
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };
  return (
    <section className="bg-neutral-900 px-6 py-16 lg:py-24">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-14">
          <div className="bg-neutral-800 shadow-md p-6 rounded-xl col-span-1 md:col-span-2 lg:col-span-1">
            <Fade delay={200} triggerOnce>
              <a href="tel:905-216-0061" className="block mb-4">
                <FiPhone size={30} className="text-cred-100 mb-2" />
                <span className="block text-white">905-216-0061</span>
              </a>
            </Fade>
            <Fade delay={300} triggerOnce>
              <a href="tel:2897526194" className="block mb-4">
                <FiPrinter size={30} className="text-cred-100 mb-2" />
                <span className="block text-white">289-752-6194</span>
              </a>
            </Fade>
            <Fade delay={400} triggerOnce>
              <a href="mailto:admin@deltamo.ca" className="block mb-4">
                <FiMail size={30} className="text-cred-100 mb-2" />
                <span className="block text-white">admin@deltamo.ca</span>
              </a>
            </Fade>
            <Fade triggerOnce>
              <div className="">
                <FiMapPin size={30} className="text-cred-100 mb-2" />
                <p className="text-white">
                  235 Queen St E
                  <br />
                  Brampton,ON, L6W 2B5
                </p>
              </div>
            </Fade>
          </div>
          <div className="col-span-1 md:col-span-2 lg:col-span-3">
            <Fade direction="up" triggerOnce>
              <div className="bg-neutral-800 shadow-md p-6 rounded-xl">
                <ContactForm onFormSubmit={onFormSubmit} />
              </div>
            </Fade>
          </div>
        </div>
        <Fade triggerOnce>
          <Map />
        </Fade>
      </div>
    </section>
  );
};

export default ContactScreen;
