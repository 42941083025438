import TableItem from "./TableItem";

interface calculatorProps {
  theadData: string[];
  tbodyData: {
    term: string;
    ratesFrom: string;
    payment: string;
    ratesFromText: string;
    ratesUpto: string;
  }[];
}

export default function Calculator({ theadData, tbodyData }: calculatorProps) {
  return (
    <div className="">
      <div className="overflow-x-auto rounded-md">
        <table className="min-w-full">
          <thead className="">
            <tr>
              {theadData.map((item, index) => {
                return (
                  <th
                    key={index}
                    className="py-2 ps-2 min-w-[160px] text-start"
                  >
                    <span></span>
                    <span>{item}</span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tbodyData.map((item, index) => {
              return <TableItem key={index} data={item} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
