import React from "react";
import { FaArrowUp } from "react-icons/fa";

export default function ScrollToTop() {
  const [visible, setVisible] = React.useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 800) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);
  return (
    <button
      onClick={scrollToTop}
      className={`fixed bottom-24 right-2 bg-cred-100 text-white rounded-full p-4 hover:scale-105 md:bottom-36 ${
        visible ? "block" : "hidden"
      }`}
    >
      <FaArrowUp color="#fff" />
    </button>
  );
}
