import axios, { BACKEND_URL } from "../axios";

class AgentModel {
  static endPoint = "agent/";

  static getAll = (url: String) => {
    return axios.get(BACKEND_URL + this.endPoint + url);
  }
  // static endDirectorPoint = "agent/";

  // static getAllDirector() {
  //   return axios.get(BACKEND_URL + this.endPoint);
  // }
}

export default AgentModel;
