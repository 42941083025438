import React from "react";
import { Button } from "../common";
import { BsChevronRight } from "react-icons/bs";

export default function CallToAction() {
  return (
    <section className="bg-cred-100 py-16">
      <div className="container mx-auto px-6 flex flex-col items-center gap-6">
        <h2 className="text-3xl lg:text-4xl text-white text-center">
          Need an Expert Advice For Your Mortgage?
        </h2>
        <Button variant="light" className="flex items-center gap-2">
          <span>Get in Touch</span>
          <BsChevronRight />
        </Button>
      </div>
    </section>
  );
}
