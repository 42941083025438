import { DefaultLayout } from "../layouts";
import { ContactScreen, TitleSection } from "../components";
import { MdHomeFilled } from "react-icons/md";

export default function ContactPage() {
  const breadCrumbs = [
    { title: <MdHomeFilled />, link: "/" },
    { title: "Contact Us", link: "#" },
  ];

  return (
    <DefaultLayout footerVariant="light">
      <TitleSection
        title="Contact Us"
        bg="/images/contact-bg1.png"
        breadCrumbs={breadCrumbs}
      />
      <ContactScreen />
    </DefaultLayout>
  );
}
