import { ContactForm, Modal } from "../..";
import { ASSETS_URL, AgentInquiryModel } from "../../../../service";
import { toast } from "react-toastify";
// import { Row } from 'react-bootstrap/Row';

type modalProps = {
  title: string;
  open: boolean;
  toggleModal: () => void;
  data: {
    _id: string;
    name: string;
    email: string;
    designation: string;
    phone: string;
    license: string;
    image: string;
    description?: string;
  };
};

type formValues = {
  name: string;
  email: string;
  subject: string;
  message: string;
};

const TeamMemberModal = (props: modalProps) => {
  const onFormSubmit = async (values: formValues) => {
    try {
      let body = { ...values, agentId: props.data._id };
      let response = await AgentInquiryModel.registerInquiry(body);
      if (response.status == 200) {
        toast.success("Inquiry Submitted!");
        props.toggleModal();
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };
  return (
    <Modal title="Profile" open={props.open} toggleModal={props.toggleModal}>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 p-2">
        <div>
          {" "}
          <img
            className="mb-4"
            src={
              props.data.image === "avatar.jpg"
                ? "/images/avatar.png"
                : ASSETS_URL + props.data.image
            }
            alt={props.data.name}
          />
          <h3 className="text-md text-center font-semibold mb-0">
            {props.data.name}
          </h3>
          <p className="text-gray-600 text-center text-sm mb-2">
            <span className="font-semibold"></span> {props.data.designation}
          </p>
        </div>
        <div className="col-span-2">
          {props.data.description !== "NA" &&
            props.data.description?.split("\\n").map((line, i) => (
              <p
                key={i}
                className="text-gray-600 text-sm text-justify leading-5 font-medium my-6 mx-2 md:mx-6 lg:mx-8 xl:mx-10"
              >
                {line}
              </p>
            ))}
        </div>
      </div>

      <div className="mt-6 flex flex-row items-center justify-center">
        <p className="text-gray-600 text-sm mb-2 mr-4">
          <span className="font-semibold">License#:</span> {props.data.license}
        </p>
        <p className="text-gray-600 text-sm mb-2 mr-4">
          <span className="font-semibold">Email:</span> {props.data.email}
        </p>
        <p className="text-gray-600 text-sm mb-2">
          <span className="font-semibold">Phone:</span> {props.data.phone}
        </p>
      </div>

      <hr className="border-t my-4 w-full border-red-500 border-b-2" />

      <div className="border-0 mt-8">
        <h3 className="text-lg font-semibold text-center mb-2">
          Send Me a Message
        </h3>
        <div className="px-5">
          <ContactForm onFormSubmit={onFormSubmit} />
        </div>
      </div>
    </Modal>
  );
};

export default TeamMemberModal;
