import { FormikHelpers, useFormik } from "formik";
import { contactValidation } from "../../utils/schemas";
import Input from "./Input";
import Textarea from "./Textarea";
import Button from "./Button";

type formValues = {
  name: string;
  email: string;
  subject: string;
  message: string;
};

interface formProps {
  onFormSubmit: (
    values: formValues,
    formikHelpers: FormikHelpers<formValues>
  ) => void;
}

const ContactForm: React.FC<formProps> = ({ onFormSubmit }) => {
  const initialValues: formValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };
  const { values, handleChange, handleSubmit, errors, touched, isSubmitting } =
    useFormik({
      initialValues: initialValues,
      validationSchema: contactValidation,
      onSubmit: onFormSubmit,
    });
  return (
    <form onSubmit={handleSubmit}>
      <Input
        type="text"
        label="Full Name"
        name="name"
        placeholder="Enter Name"
        value={values.name}
        onChange={handleChange}
        errors={errors.name}
        touched={touched.name}
        readOnly={isSubmitting}
        labelClass="text-white"
      />
      <Input
        type="email"
        label="Email"
        name="email"
        placeholder="Enter Email"
        value={values.email}
        onChange={handleChange}
        errors={errors.email}
        touched={touched.email}
        readOnly={isSubmitting}
        labelClass="text-white"
      />
      <Input
        type="text"
        label="Subject"
        name="subject"
        placeholder="Enter Subject"
        value={values.subject}
        onChange={handleChange}
        errors={errors.subject}
        touched={touched.subject}
        readOnly={isSubmitting}
        labelClass="text-white"
      />
      <Textarea
        rows={3}
        label="Message"
        name="message"
        placeholder="Enter Message"
        value={values.message}
        onChange={handleChange}
        errors={errors.message}
        touched={touched.message}
        readOnly={isSubmitting}
        labelClass="text-white"
      />
      <Button
        variant="hover-light"
        type="submit"
        fullWidth={true}
        isDisabled={isSubmitting}
      >
        Send Message
      </Button>
    </form>
  );
};

export default ContactForm;
