import axios, { BACKEND_URL } from "../axios";

class MessageModel {
  static endPoint = "message/";

  static registerMessage(body: any) {
    return axios.post(BACKEND_URL + this.endPoint + "messageRegister", body);
  }
}

export default MessageModel;
