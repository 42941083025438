import { ResidentialScreen, TitleSection } from "../components";
import { DefaultLayout } from "../layouts";

export default function ResidentialPage() {
  return (
    <DefaultLayout>
      <TitleSection
        title="Residential Mortgage"
        bg="/images/residential-bg.jpg"
      />
      <ResidentialScreen />
    </DefaultLayout>
  );
}
