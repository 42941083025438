import React from 'react'
import { DefaultLayout } from '../layouts'
import { HomeScreen } from '../components'

export default function HomePage() {
  return (
    <DefaultLayout showApplyNowButton={false}>
      <HomeScreen />
    </DefaultLayout>
  )
}
