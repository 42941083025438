import React from "react";
import { FaCheckCircle } from "react-icons/fa";

interface RadioCardProps {
  label: string;
  value: string;
  inputName: string;
  selectedValue: string;
  setSelectedValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioCard: React.FC<RadioCardProps> = ({
  label,
  value,
  inputName,
  selectedValue,
  setSelectedValue,
}) => {
  const radioRef = React.useRef<HTMLInputElement>(null);
  return (
    <div
      className={`flex items-center justify-between cursor-pointer border-2 border-cred-100 rounded p-3 ${
        selectedValue === value && "bg-cred-100 text-white"
      }`}
      onClick={() => {
        if (radioRef.current !== null) {
          radioRef.current.click();
        }
      }}
    >
      <p>{label}</p>
      {selectedValue === value && <FaCheckCircle size={20} />}
      <input
        ref={radioRef}
        type="radio"
        name={inputName}
        value={value}
        onChange={setSelectedValue}
        className="hidden"
      />
    </div>
  );
};

export default RadioCard;
