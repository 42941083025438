import React from 'react';
import { DefaultLayout } from '../layouts';
import { TitleSection } from "../components";
import Testimonial  from '../components/home-screen/Testimonials';

export default function LenderPage() {
  return (
    <DefaultLayout showApplyNowButton={false}>
      <TitleSection title="Testimonial" bg="/images/team-bg.jpg" />
      <Testimonial />
    </DefaultLayout>
  )
}
