import { useFormik } from "formik";
import { Button, Input, NumberInput } from "../../common";
import { BsChevronRight } from "react-icons/bs";
import { applyFormSchema } from "../../../utils/schemas";

type radioProps = {
  label: string;
  name: string;
  value: string;
  formikVal: string;
  setFormikVal: (type: string, val: string) => void;
};

const RadioBox = ({
  label,
  name,
  value,
  formikVal,
  setFormikVal,
}: radioProps) => {
  return (
    <label
      htmlFor={value}
      className={`px-4 py-2 border rounded-lg cursor-pointer hover:text-cred-100 hover:border-cred-100 md:px-5 md:py-3 ${
        value === formikVal ? "text-cred-100 border-cred-100" : "text-gray-500"
      }`}
    >
      <input
        type="radio"
        name={name}
        id={value}
        value={value}
        checked={value === formikVal}
        onChange={(e) => {
          if (e.target.checked) {
            setFormikVal(name, value);
          }
        }}
        className="hidden"
      />
      {label}
    </label>
  );
};

type stepProps = {
  onNextStep: () => void;
  updateFormValues: (values: any) => void;
};

export default function ApplyStepOne({
  onNextStep,
  updateFormValues,
}: stepProps) {
  const formik = useFormik({
    initialValues: {
      mortgageType: "",
      mortgageAmount: "",
      fundRequired: "",
      livingStatus: "",
      noOfApplicants: "",
    },
    validationSchema: applyFormSchema,
    onSubmit: () => {
      updateFormValues(formik.values);
      onNextStep();
    },
  });

  return (
    <div className="col-span-2 p-4 md:p-6">
      <div className="mb-3">
        <p className="font-semibold mb-3">1. Select Mortgage Type</p>
        <div className="flex flex-wrap gap-3 ms-3">
          <RadioBox
            label="Pre Qualify"
            name="mortgageType"
            value="pre-qualify"
            formikVal={formik.values.mortgageType}
            setFormikVal={formik.setFieldValue}
          />
          <RadioBox
            label="Purchase"
            name="mortgageType"
            value="purchase"
            formikVal={formik.values.mortgageType}
            setFormikVal={formik.setFieldValue}
          />
          <RadioBox
            label="Refinance"
            name="mortgageType"
            value="refinance"
            formikVal={formik.values.mortgageType}
            setFormikVal={formik.setFieldValue}
          />
          <RadioBox
            label="Renew/Switch"
            name="mortgageType"
            value="renew-switch"
            formikVal={formik.values.mortgageType}
            setFormikVal={formik.setFieldValue}
          />
        </div>
        {formik.touched.mortgageType && formik.errors.mortgageType && (
          <p className="text-cred-100 ms-3 mt-1 text-sm">
            {formik.errors.mortgageType}
          </p>
        )}
      </div>
      <div className="mb-3">
        <p className="font-semibold mb-3">2. Mortgage Amount Required</p>
        <div className="ms-3">
          <NumberInput
            type="number"
            name="mortgageAmount"
            leadingIcon="$"
            placeholder="Enter amount"
            value={formik.values.mortgageAmount}
            onChange={(val) => formik.setFieldValue("mortgageAmount", val)}
            touched={formik.touched.mortgageAmount}
            errors={formik.errors.mortgageAmount}
          />
        </div>
      </div>
      <div className="mb-3">
        <p className="font-semibold mb-3">3. Select Funds Required</p>
        <div className="flex flex-wrap gap-3 ms-3">
          <RadioBox
            label="Within 45 days"
            name="fundRequired"
            value="within-45-days"
            formikVal={formik.values.fundRequired}
            setFormikVal={formik.setFieldValue}
          />
          <RadioBox
            label="Within 20 days"
            name="fundRequired"
            value="within 20 days"
            formikVal={formik.values.fundRequired}
            setFormikVal={formik.setFieldValue}
          />
          <RadioBox
            label="Unsure"
            name="fundRequired"
            value="unsure"
            formikVal={formik.values.fundRequired}
            setFormikVal={formik.setFieldValue}
          />
          <RadioBox
            label="Others"
            name="fundRequired"
            value="other"
            formikVal={formik.values.fundRequired}
            setFormikVal={formik.setFieldValue}
          />
        </div>
        {formik.touched.fundRequired && formik.errors.fundRequired && (
          <p className="text-cred-100 ms-3 mt-1 text-sm">
            {formik.errors.fundRequired}
          </p>
        )}
      </div>
      <div className="mb-3">
        <p className="font-semibold mb-3">4. Current Living Status</p>
        <div className="flex flex-wrap gap-3 ms-3">
          <RadioBox
            label="Own"
            name="livingStatus"
            value="own"
            formikVal={formik.values.livingStatus}
            setFormikVal={formik.setFieldValue}
          />
          <RadioBox
            label="Rent"
            name="livingStatus"
            value="rent"
            formikVal={formik.values.livingStatus}
            setFormikVal={formik.setFieldValue}
          />
          <RadioBox
            label="Living with Parents"
            name="livingStatus"
            value="living-with-parents"
            formikVal={formik.values.livingStatus}
            setFormikVal={formik.setFieldValue}
          />
        </div>
        {formik.touched.livingStatus && formik.errors.livingStatus && (
          <p className="text-cred-100 ms-3 mt-1 text-sm">
            {formik.errors.livingStatus}
          </p>
        )}
      </div>
      <div className="mb-3">
        <p className="font-semibold mb-3">5. Number of Applicants</p>
        <div className="flex flex-wrap gap-3 ms-3">
          <RadioBox
            label="Single"
            name="noOfApplicants"
            value="single"
            formikVal={formik.values.noOfApplicants}
            setFormikVal={formik.setFieldValue}
          />
          <RadioBox
            label="Co-applicant"
            name="noOfApplicants"
            value="co-applicant"
            formikVal={formik.values.noOfApplicants}
            setFormikVal={formik.setFieldValue}
          />
          <RadioBox
            label="Others"
            name="noOfApplicants"
            value="others"
            formikVal={formik.values.noOfApplicants}
            setFormikVal={formik.setFieldValue}
          />
        </div>
        {formik.touched.noOfApplicants && formik.errors.noOfApplicants && (
          <p className="text-cred-100 ms-3 mt-1 text-sm">
            {formik.errors.noOfApplicants}
          </p>
        )}
      </div>
      <Button
        type="submit"
        variant="primary"
        className="flex items-center gap-2"
        onClick={formik.handleSubmit}
      >
        <span>Next</span>
        <BsChevronRight />
      </Button>
    </div>
  );
}
