import { useState } from "react";
import { DefaultLayout } from "../layouts";
import { TitleSection } from "../components";
import FAQs from "../components/faq-screen";
import { Fade } from "react-awesome-reveal";


type faqObj = {
  question: string;
  asnwer: () => JSX.Element;
};

export default function LenderPage() {
  const [faq, setFaq] = useState<faqObj[]>([
    {
      question: "How can Delta Mortgages help you?",
      asnwer: () => {
        return (
          <>
            <h1>
            Buying a home or a property is one of the biggest decisions you’ll ever make. It can also <br/>
            be a stressful and confusing process. That’s why you need a trusted partner who can 
            guide <br/>you through it.<br/>
            Delta Mortgages is more than just a mortgage specialist. They are your advisor who can <br/>
            help you with all your financial needs related to your home or property.<br/>
            Whether you’re a first-time buyer or a seasoned investor, a Delta Mortgage Advisor can <br/>
            offer you customized mortgage advice and financing solutions that work for you.
            You can <br/>
            reach out to a Delta Mortgage Advisor anytime, anywhere, and any way you<br/>
             want - in person, online, or on the phone.
            </h1>
          </>
        );
      },
    },

    {
        question: "When should I contact Delta Mortgages?",
        asnwer: () => {
          return (
            <>
              <h1>
              You can contact Delta Mortgages anytime you have a question or a need related to your  <br/>
              home or property. <br/>For example, you can contact us if you want to:<br/>
              <ul>
                <li className="mx-4"><b>⦿ </b> Buy your first or next home</li>
                <li className="mx-4"><b>⦿ </b> Buy a cottage or rental property</li>
                <li className="mx-4"><b>⦿ </b> Refinance or use your existing equity for renovations</li>
                <li className="mx-4"><b>⦿ </b> Switch your mortgage from another lender</li>
                <li className="mx-4"><b>⦿ </b> Buy a condo with existing tenants</li>
                <li className="mx-4"><b>⦿ </b> Buy your first home as a new Canadian</li>
                </ul>
                And more. Just ask us.
              </h1>
            </>
          );
        },
      },

      {
        question: "How much can I borrow from Delta Mortgages?",
        asnwer: () => {
          return (
            <>
              <h1>
              The amount you can borrow from Delta Mortgages depends on several factors, such as<br/>
               your income, your debt, your credit history, your down payment, and the type of property<br/>
                you want to buy. To get an estimate of how much you can borrow, you can use our online <br/>
                mortgage calculator or contact one of our Delta Mortgage Advisors.
              </h1>
            </>
          );
        },
      },

      {
        question: "What are the benefits of getting pre-approved by Delta Mortgages?",
        asnwer: () => {
          return (
            <>
              <h1>
              Getting pre-approved by Delta Mortgages means you have a clear idea of how much you <br/>
              can afford to spend on a home, and what your monthly payments will be. It also shows <br/>
              sellers and realtors that you are a serious and qualified buyer, which can give you an edge <br/>
              in a competitive market. Plus, you can lock in your interest rate for up to 120 days, so <br/>
              you don’t have to worry about rate fluctuations.
              </h1>
            </>
          );
        },
      },

      {
        question: "What are the different types of mortgages offered by Delta Mortgages?",
        asnwer: () => {
          return (
            <>
              <h1>
              Delta Mortgages offers a variety of mortgage products to suit your needs and preferences.<br/>
               Some of the most common types are:<br/><br/>
               <ul>
                <li className="mx-4"><b>⦿ Fixed-rate mortgage:</b> This is a mortgage where the interest rate and the monthly <br/>
                payments stay the same for the entire term of the loan. This is a good option if you <br/>
                want stability and predictability in your budget.
                </li><br/>

                <li className="mx-4"><b>⦿ Variable-rate mortgage:</b> This is a mortgage where the interest rate and the monthly <br/>
                payments may change from time to time, depending on the market conditions. <br/>
                This is a good option if you want to take advantage of lower rates and save money <br/>
                in the long run
                </li><br/>

                <li className="mx-4"><b>⦿ Open mortgage:</b> This is a mortgage that allows you to make extra payments or pay <br/>
                off the entire balance at any time, without any penalty. This is a good option if you <br/>
                plan to sell your home or pay off your mortgage sooner.
                </li><br/>

                <li className="mx-4"><b>⦿ Closed mortgage:</b> This is a mortgage that limits the amount of extra payments you <br/>
                can make or charges a penalty if you pay off the entire balance before the end of the term. <br/>
                This is a good option if you want a lower interest rate and don’t plan to <br/>
                make any changes to your mortgage.
                </li>
               </ul>
              </h1>
            </>
          );
        },
      },

      {
        question: "What makes Delta Mortgages different?",
        asnwer: () => {
          return (
            <>
              <h1>
              <ul>
                <li className="mx-4"><b>⦿ Convenient:</b> You don’t have to adjust your schedule to meet with us. We can connect <br/>
                with you at your preferred time and place, whether it’s in person, virtually, or over the phone.
                </li>
                <li className="mx-4"><b>⦿ Personalized:</b> We don’t just offer you a mortgage, but a comprehensive financial plan <br/>
                that suits your goals and budget.
                </li>
                </ul>
              </h1>
            </>
          );
        },
      },

      {
        question: "How can I save money on my mortgage with Delta Mortgages?",
        asnwer: () => {
          return (
            <>
              <h1>
              There are several ways you can save money on your mortgage with Delta Mortgages, such as:<br/><br/>
              <ul>
                <li className="mx-4"><b>⦿ Making a larger down payment:</b>The more money you put down on your home, <br/>
                the less you have to borrow and the less interest you have to pay. Plus, if you make a <br/>
                down payment of at least 20% of the purchase price, you can avoid paying mortgage default <br/>
                insurance, which can add to your costs.
                </li><br/>

                <li className="mx-4"><b>⦿ Choosing a shorter amortization period:</b> The amortization period is the length of <br/>
                time it takes to pay off your mortgage in full. The shorter the amortization period, <br/>
                the less interest you have to pay over the life of the loan. However, this also means your <br/>
                monthly payments will be higher, so you have to make sure you can afford them.
                </li><br/>

                <li className="mx-4"><b>⦿ Making extra payments:</b> You can reduce your interest costs and pay off your <br/>
                mortgage faster by making extra payments whenever you can. You can do this by <br/>
                increasing your regular payment amount, making lump-sum payments, or taking advantage <br/>
                of prepayment privileges. Check your mortgage contract or talk to your Delta Mortgage <br/>
                Advisor to find out what options are available to you.
                </li><br/>

                <li className="mx-4"><b>⦿ Switching to a lower interest rate:</b>If interest rates have dropped since you got your <br/>
                mortgage, you may be able to save money by switching to a lower rate. However, <br/>
                you have to consider the costs of breaking your current mortgage contract, such as <br/>
                prepayment penalties and fees. You can use our online mortgage switch calculator or <br/>
                contact your Delta Mortgage Advisor to find out if switching makes sense for you.
                </li>
                </ul>
              </h1>
            </>
          );
        },
      },
  ]);

  return (
    <DefaultLayout showApplyNowButton={false}>
      <TitleSection title="FAQs" bg="/images/team-bg.jpg" />

      <div className="py-16 lg:py-24">
        <section className="bg-neutral-100">
          <div className="container mx-auto px-6 py-12 bg-slate-100">
            <Fade direction="up" triggerOnce>
              <div className="flex justify-center items-center gap-3 text-cred-70 mb-6">
                <span className="h-0.5 w-12 bg-cred-100" />
                <p className="font-bold">FAQ's</p>
                <span className="h-0.5 w-12 bg-cred-100" />
              </div>
              <h2 className="text-4xl lg:text-5xl font-bold text-center mb-8">
                Our FAQs
              </h2>
            </Fade>
            {faq.map((item, index) => (
              <FAQs question={item.question} answer={item.asnwer} />
            ))}
          </div>
        </section>
      </div>
    </DefaultLayout>
  );
}
