import React from "react";
import ApplyStepOne from "./ApplyStepOne";
import ApplyStepTwo from "./ApplyStepTwo";
import ApplyStepThree from "./ApplyStepThree";
import ApplyStepFour from "./ApplyStepFour";
import ApplyStepFive from "./ApplyStepFive";
import ApplyStepSix from "./ApplyStepSix";
import { Button } from "../common";
import ApplyThankYou from "./ApplyThankYou";
import { useNavigate } from "react-router-dom";
import { ApplyFormModel } from "../../service";
import { toast } from "react-toastify";

type formObject = {
  [key: string]: string;
};

export default function ApplyNowScreen() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [formValues, setFormValues] = React.useState({});

  const onNextStep = () => setActiveStep(activeStep + 1);
  const onBackStep = () => setActiveStep(activeStep - 1);

  const updateFormValues = (key: string, value: any) => {
    let tempValues: formObject = { ...formValues };
    tempValues[key] = value;
    setFormValues(tempValues);
  };

  const onFormSubmit = async (values: any) => {
    let form: formObject = { ...formValues };
    form.fullName = values.fullName;
    form.email = values.email;
    form.phone = values.phone;
    form.province = values.province;
    form.specialist = values.specialist === "" ? "N/A" : values.specialist;
    try {
      let response = await ApplyFormModel.register(form);
      if (response.status === 200) {
        setActiveStep(activeStep + 1);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  const steps = [
    <ApplyStepOne
      formValues={formValues}
      onNextStep={onNextStep}
      updateFormValues={updateFormValues}
    />,
    <ApplyStepTwo
      formValues={formValues}
      onNextStep={onNextStep}
      onBackStep={onBackStep}
      updateFormValues={updateFormValues}
    />,
    <ApplyStepThree
      formValues={formValues}
      onNextStep={onNextStep}
      onBackStep={onBackStep}
      updateFormValues={updateFormValues}
    />,
    <ApplyStepFour
      formValues={formValues}
      onNextStep={onNextStep}
      onBackStep={onBackStep}
      updateFormValues={updateFormValues}
    />,
    <ApplyStepFive
      formValues={formValues}
      onNextStep={onNextStep}
      onBackStep={onBackStep}
      updateFormValues={updateFormValues}
    />,
    <ApplyStepSix
      formValues={formValues}
      onBackStep={onBackStep}
      updateFormValues={setFormValues}
      onFormSubmit={onFormSubmit}
    />,
    <ApplyThankYou />,
  ];

  return (
    <>
      <div className="flex justify-center mt-8 mb-10">
        {activeStep < steps.length - 1 &&
          Array.from(Array(steps.length - 1).keys()).map((_, i) => {
            if (i < steps.length - 2) {
              return (
                <div key={i} className="flex items-center">
                  <p
                    className={`border-2 border-cred-100 rounded-full px-3 py-1 ${
                      activeStep >= i && "bg-cred-100 text-white"
                    }`}
                  >
                    {i + 1}
                  </p>
                  <hr
                    className={`w-3 md:w-10 border-2 ${
                      activeStep > i && "border-cred-100"
                    }`}
                  />
                </div>
              );
            } else {
              return (
                <p
                  key={i}
                  className={`border-2 border-cred-100 rounded-full px-3 py-1 ${
                    activeStep >= i && "bg-cred-100 text-white"
                  }`}
                >
                  {i + 1}
                </p>
              );
            }
          })}
      </div>
      <div className="flex items-center justify-center pt-0 pb-10 px-6 md:px-40">
        <div className="w-full">
          {steps[activeStep]}
          {activeStep > steps.length - 2 && (
            <div className="flex justify-center">
              <Button onClick={() => navigate("/")}>Go Back to Home</Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
