import React from "react";
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaTwitter,
} from "react-icons/fa";

const Topbar: React.FC = () => {
  const contactDetails = [
    {
      icon: <FaMapMarkerAlt />,
      text: "235 Queen St E, Brampton, ON, L6W 2B5",
    },
    {
      icon: <FaEnvelope />,
      text: "admin@deltamo.ca",
    },
  ];

  const socialMediaLinks = [
    {
      icon: <FaFacebookF />,
      link: "https://www.facebook.com/deltamortgages/?modal=admin_todo_tour",
    },
    {
      icon: <FaTwitter />,
      link: "https://twitter.com/DeltaMortgages?s=08",
    },
    {
      icon: <FaInstagram />,
      link: "https://www.instagram.com/p/CCuXYckAqiw/?igshid=s2r0847jjchy",
    },
    {
      icon: <FaLinkedinIn />,
      link: "https://www.linkedin.com/mwlite/in/delta-mortgages-1179491b2",
    },
  ];
  return (
    <div className="bg-gray-800 hidden">
      <div className="container mx-auto py-3 px-6 flex justify-between items-center">
        <ul className="flex gap-x-5">
          {contactDetails.map((item, i) => {
            return (
              <li key={i} className="flex items-center text-white text-sm">
                <span className="text-base">{item.icon}</span>
                <span className="ml-2">{item.text}</span>
              </li>
            );
          })}
        </ul>
        <ul className="flex gap-x-2">
          {socialMediaLinks.map((item, i) => {
            return (
              <li key={i}>
                <a
                  href={item.link}
                  className="block text-white p-2 rounded-full bg-transparent hover:bg-cred-100"
                >
                  {item.icon}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Topbar;
