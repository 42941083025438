import React from "react";
import {
  Footer,
  Header,
  Topbar,
  SocialMediaIcons,
  WhatsAppIcon,
  ScrollToTop,
} from "./partials";

interface DefaultLayoutProps {
  children: React.ReactNode;
  showApplyNowButton?: boolean; // Define the prop here
  footerVariant?: string;
}

export default function DefaultLayout({
  children,
  showApplyNowButton = true, // Set default value for showApplyNowButton
  footerVariant,
}: DefaultLayoutProps) {
  return (
    <div>
      <Topbar />
      {/* Pass the showApplyNowButton prop to the Header component */}
      <Header showApplyNowButton={showApplyNowButton} />
      <main>{children}</main>
      <Footer variant={footerVariant} />
      {/* <SocialMediaIcons /> */}
      <WhatsAppIcon />
      <ScrollToTop />
    </div>
  );
}
