import { useFormik } from "formik";
import { Button, CSelect, Input } from "../common";
import { applyContactSchema } from "../../utils/schemas";
import { SetStateAction } from "react";

type stepProps = {
  formValues: any;
  onBackStep: () => void;
  updateFormValues: SetStateAction<any>;
  onFormSubmit: (values: any) => void;
};

export default function ApplyStepSix({
  formValues,
  onBackStep,
  updateFormValues,
  onFormSubmit,
}: stepProps) {
  const options = [
    { label: "Please Choose an Option", value: "" },
    { label: "Alberta(AB)", value: "AB" },
    { label: "British Columbia(BC)", value: "BC" },
    { label: "Manitoba(MB)", value: "MB" },
    { label: "New Brunswick(NB)", value: "NB" },
    { label: "New Foundland and Labrador(NL)", value: "NL" },
    { label: "Nova Scotia(NS)", value: "NS" },
    { label: "Norhtwest Territories(NT)", value: "NT" },
    { label: "Nunavut(NU)", value: "NU" },
    { label: "Ontario(ON)", value: "ON" },
    { label: "Prince Edward Island(PE)", value: "PE" },
    { label: "Quebec(QC)", value: "QC" },
    { label: "New Brunswick(NB)", value: "NB" },
    { label: "Saskatchewan(SK)", value: "SK" },
    { label: "Yukon(NB)", value: "YT" },
  ];

  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      fullName: formValues?.fullName ?? "",
      email: formValues?.email ?? "",
      phone: formValues?.phone ?? "",
      province: formValues?.province ?? "",
      specialist: formValues?.specialist ?? "",
    },
    validationSchema: applyContactSchema,
    onSubmit: onFormSubmit,
  });

  return (
    <div className="mb-4 text-lg">
      <h2 className="text-3xl font-bold text-center mb-4">
        Contact Information
      </h2>
      <form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="fullName"
          label="Full Name"
          placeholder="Enter Full Name"
          value={values.fullName}
          onChange={handleChange}
          errors={errors.fullName}
          touched={touched.fullName}
        />
        <Input
          type="email"
          name="email"
          label="Email"
          placeholder="Enter Email"
          value={values.email}
          onChange={handleChange}
          errors={errors.email}
          touched={touched.email}
        />
        <Input
          type="tel"
          name="phone"
          label="Phone"
          placeholder="Enter Phone"
          value={values.phone}
          onChange={handleChange}
          errors={errors.phone}
          touched={touched.phone}
        />
        <CSelect
          name="province"
          label="Province"
          options={options}
          value={values.province}
          onChange={(e) => setFieldValue("province", e.target.value)}
          errors={errors.province}
          touched={touched.province}
        />
        <Input
          type="text"
          name="specialist"
          label="Mortgage Specialist"
          placeholder="Enter Specialist's Name"
          value={values.specialist}
          onChange={handleChange}
          errors={errors.specialist}
          touched={touched.specialist}
        />
        <p className="text-sm text-gray-500">
          (if you have already spoken to one of our specialists then please
          enter their name.)
        </p>
        <div className="flex justify-end gap-3">
          <button
            type="button"
            className="border-2 border-neutral-900 bg-neutral-900 text-white rounded-lg font-semibold py-3 px-6 hover:bg-white hover:text-cred-100 hover:border-cred-100 disabled:opacity-80"
            onClick={() => {
              updateFormValues({ ...formValues, ...values });
              onBackStep();
            }}
            disabled={isSubmitting}
          >
            Back
          </button>
          <Button type="submit" isDisabled={isSubmitting}>
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}
