import { MdHomeFilled } from "react-icons/md";
import { DefaultLayout } from "../../layouts";
import { FirstHomeScreen, TitleSection } from "../../components";

export default function FirstHome() {
  const breadCrumbs = [
    { title: <MdHomeFilled />, link: "/" },
    { title: "Services", link: "#" },
    { title: "First Home", link: "#" },
  ];

  return (
    <DefaultLayout>
      <TitleSection
        title="Services"
        bg="/images/first-home-bg.png"
        breadCrumbs={breadCrumbs}
      />
      <FirstHomeScreen />
    </DefaultLayout>
  );
}
