import { Fade } from "react-awesome-reveal";
import GetInTouchSection from "../GetInTouchSection";
import HELOCLimitCalculator from "./HELOCLimitCalculator";
import HELOCCalculator from "./HELOCCalculator";

export default function HELOCScreen() {
  return (
    <>
      <section className="px-6 py-16 lg:py-24">
        <div className="container mx-auto">
          <Fade direction="up" triggerOnce>
            <h2 className="text-4xl lg:text-5xl mb-4">
              HELOC (Home Equity Line of Credit)
            </h2>
            <p className="mb-10">
              Tap into your home’s equity with our flexible HELOC options.
              Finance your dreams, from renovations to education, while
              maintaining control of your expenses.
              <br />
              <br />
              With Delta Mortgages Inc.'s HELOC, you have a revolving credit
              line to use as needed, paying interest only on what you borrow.
              Ideal for ongoing projects or unexpected expenses, our HELOC
              solutions offer the flexibility and convenience you need, with
              competitive rates and easy access to funds.
            </p>
          </Fade>
          <Fade direction="up" triggerOnce>
            <HELOCLimitCalculator />
          </Fade>
        </div>
      </section>
      <GetInTouchSection />
    </>
  );
}
