import { Fade } from "react-awesome-reveal";
import GetInTouchSection from "../GetInTouchSection";
import PrepaymentChargeCalculator from "./PrepaymentChargeCalculator";

export default function PrivateMortgagesScreen() {
  return (
    <>
      <section className="px-6 py-16 lg:py-24">
        <div className="container mx-auto">
          <Fade direction="up" triggerOnce>
            <h2 className="text-4xl lg:text-5xl mb-4">Private Mortgages</h2>
            <p className="mb-10">
              Unique financial situations require unique solutions. Our private
              mortgages provide flexible options for those who may not meet
              traditional lending criteria.
              <br />
              <br />
              For those with unique lending needs, our Private Mortgages offer a
              solution. Whether it’s due to non-traditional income or credit
              issues, we work with a network of private lenders to find a
              mortgage that suits your circumstances, ensuring you have access
              to the financing you need.
            </p>
          </Fade>
          <Fade direction="up" triggerOnce>
            <PrepaymentChargeCalculator />
          </Fade>
        </div>
      </section>
      <GetInTouchSection />
    </>
  );
}
