import { MdHomeFilled } from "react-icons/md";
import { DefaultLayout } from "../../layouts";
import { RefinanceScreen, TitleSection } from "../../components";

export default function Refinance() {
  const breadCrumbs = [
    { title: <MdHomeFilled />, link: "/" },
    { title: "Services", link: "#" },
    { title: "Refinance", link: "#" },
  ];

  return (
    <DefaultLayout>
      <TitleSection
        title="Services"
        bg="/images/refinance-bg.png"
        breadCrumbs={breadCrumbs}
      />
      <RefinanceScreen />
    </DefaultLayout>
  );
}
