import React from "react";
import { Button, Input, NumberInput } from "../common";

type stepProps = {
  formValues: any;
  onNextStep: () => void;
  onBackStep: () => void;
  updateFormValues: (key: string, value: any) => void;
};

export default function ApplyStepTwo({
  formValues,
  onNextStep,
  onBackStep,
  updateFormValues,
}: stepProps) {
  const [inputValue, setInputValue] = React.useState(
    formValues?.mortgageAmount ?? ""
  );
  const [error, setError] = React.useState("");
  const onValueChange = (val: any): void => {
    setInputValue(val);
  };

  const onSubmitStep = () => {
    if (inputValue === "" || !inputValue) {
      setError("Enter amount!");
    } else {
      updateFormValues("mortgageAmount", inputValue);
      onNextStep();
    }
  };

  return (
    <div className="mb-4 text-lg">
      <h2 className="text-3xl font-bold text-center mb-4">
        Mortgage Information
      </h2>
      <NumberInput
        name="mortgageAmount"
        label="Mortgage Amount Required"
        placeholder="Enter Amount in $"
        value={inputValue}
        onChange={onValueChange}
      />
      <p className="text-red-500">{error}</p>
      <div className="flex justify-end gap-3">
        <Button
          type="button"
          variant="secondary"
          onClick={() => {
            updateFormValues("mortgageAmount", inputValue);
            onBackStep();
          }}
        >
          Back
        </Button>
        <Button type="button" onClick={onSubmitStep}>
          Next
        </Button>
      </div>
    </div>
  );
}
