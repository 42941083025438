import axios, { BACKEND_URL } from "../axios";

class HelpModel {
    id: string = "";
    title: string = "";
    subject: string = "";
    // image: string = "";

    constructor(
        id: string,
        title: string,
        subject: string,
        // image: string
    ) {
        this.id = id;
        this.title = title;
        this.subject = subject;
        // this.image = image;
    }

    static endPoint: string = "help";

    static get(): any {
        return axios.get(BACKEND_URL + this.endPoint);
    }

    static async fromResponseToHelpList(data: any): Promise<HelpModel[]> {
        const helps: HelpModel[] = data.map((item: any) => {
          return new HelpModel(item._id, item.title, item.subject); 
        });
        return helps;
    }
      

    static async getHelp(id: string) {
        return axios.get(BACKEND_URL + this.endPoint + "/" + id);
    }

}

export default HelpModel;
