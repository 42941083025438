import React from "react";

interface InputProps {
  rows?: number;
  label?: string;
  name: string;
  placeholder?: string;
  className?: string;
  labelClass?: string;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  value?: any;
  errors?: any;
  touched?: any;
  autoFocus?: boolean;
  readOnly?: boolean;
}

const Textarea: React.FC<InputProps> = ({
  rows = 3,
  label,
  className,
  labelClass,
  errors,
  touched,
  ...inputProps
}) => {
  return (
    <div className="my-3">
      {label && (
        <label className={`block font-bold mb-2 ${labelClass}`}>{label}</label>
      )}
      <textarea
        rows={rows}
        className="w-full rounded border border-gray-300 focus:outline-gray-400 p-2"
        {...inputProps}
      />
      {errors && touched && (
        <p className="text-sm text-red-500 mt-2">{errors}</p>
      )}
    </div>
  );
};

export default Textarea;
