import { AboutScreen, TitleSection } from "../components";
import { DefaultLayout } from "../layouts";
import { MdHomeFilled } from "react-icons/md";

export default function AboutPage() {
  const breadCrumbs = [
    { title: <MdHomeFilled />, link: "/" },
    { title: "About Us", link: "#" },
  ];

  return (
    <DefaultLayout footerVariant="light">
      <TitleSection
        title="About Us"
        bg="/images/about-bg1.png"
        breadCrumbs={breadCrumbs}
      />
      <AboutScreen />
    </DefaultLayout>
  );
}
