import { SocialIcons } from "../../../components";

interface CopyrightProps {
  variant?: string;
}

export default function Copyright({ variant }: CopyrightProps) {
  return (
    <div
      className={`flex flex-wrap items-center justify-between pt-5 mt-10  border-t w-full ${
        variant === "light" ? "border-neutral-200" : "border-neutral-900"
      }`}
    >
      <p
        className={`${
          variant === "light" ? "text-gray-600" : "text-gray-300"
        } text-sm`}
      >
        &copy; Copyright Delta Mortgages 2024 - All Rights Reserved
      </p>
      <div>
        <SocialIcons
          normalBg="bg-transparent"
          hoverBg=""
          normalText="text-gray-300"
          hoverText="text-cred-100"
        />
      </div>
    </div>
  );
}
