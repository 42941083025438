import { DefaultLayout } from '../layouts';
import { TitleSection } from "../components";
import Lenders  from '../components/home-screen/Lenders';

export default function LenderPage() {
  return (
    <DefaultLayout showApplyNowButton={false}>
      <TitleSection title="Our Lenders" bg="/images/lenders.jpg" />
      <Lenders />
    </DefaultLayout>
  )
}
