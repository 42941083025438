import { DefaultLayout } from "../layouts";
import { SingleNewsScreen } from "../components";
import { Navigate, useParams } from "react-router-dom";

export default function SingleNewsPage() {
  const params = useParams();
  const reqPermalink: string | undefined = params.permalink;
  if (reqPermalink === undefined) {
    return <Navigate to="/" />;
  }
  return (
    <DefaultLayout>
      <SingleNewsScreen id={reqPermalink!} />
    </DefaultLayout>
  );
}
