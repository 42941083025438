import { useNavigate } from "react-router-dom";
import { ApplyNowScreen, Button } from "../components";
import { FaChevronLeft } from "react-icons/fa";

export default function ApplyNowPage() {
  const navigate = useNavigate();
  const gotToHome = () => {
    navigate("/");
  }
  return (
    <div className="grid grid-cols-3">
      <Button
        variant="transparent-black"
        className="flex items-center gap-2 absolute"
        onClick={gotToHome}
      >
        <FaChevronLeft />
        Go Back
      </Button>
      <div className="hidden lg:flex flex-col justify-center items-center text-center h-screen bg-cred-100/5">
        <div className="flex items-center gap-3 mb-10">
          <img src="/images/logo2.png" alt="" className="w-16" />
          <div>
            <p className="text-2xl font-bold text-start">DELTA</p>
            <p className="text-md font-semibold mb-0 text-start">
              MORTGAGES INC.
            </p>
          </div>
        </div>
        <img src="/images/apply-now.png" alt="" className="w-80" />
        <h2 className="text-2xl font-semibold my-3">Get Your Best Rate!</h2>
        <p className="px-16 mb-3">
          Fill out some information and one of our expert brokers will be in
          touch with you soon.
        </p>
      </div>
      <div className="col-span-3 lg:col-span-2 h-screen overflow-auto mt-3 lg:mt-0">
        <ApplyNowScreen />
      </div>
    </div>
  );
}
