import { Fade } from "react-awesome-reveal";
import GetInTouchSection from "../GetInTouchSection";

export default function RentalPropertyMortgagesScreen() {
  return (
    <>
      <section className="px-6 py-16 lg:py-24">
        <div className="container mx-auto">
          <Fade direction="up" triggerOnce>
            <h2 className="text-4xl lg:text-5xl mb-4">
              Rental Property Mortgages
            </h2>
            <p className="mb-10">
              Enter the rental market with confidence. Our rental property
              mortgages are crafted to balance income and expenses, making your
              investment as profitable as possible.
              <br />
              <br />
              Whether you're an experienced landlord or just starting, we offer
              rental property mortgages with competitive rates. Our financial
              experts will help you evaluate your potential ROI and provide
              funding solutions that make sense for your rental business model.
            </p>
          </Fade>
        </div>
      </section>
      <GetInTouchSection />
    </>
  );
}
