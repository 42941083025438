import { useFormik } from "formik";
import { helocLimitCalculatorSchema } from "../../../utils/schemas";
import { Button, NumberInput } from "../../common";
import { useState } from "react";

export default function HELOCLimitCalculator() {
  const [equity, setEquity] = useState<number>();
  const onCalculate = () => {
    let maxMortgage = parseInt(formik.values.homeValue) || 0;
    maxMortgage = maxMortgage * (80 / 100);
    let remainingMortgage = parseInt(formik.values.remainingMortgage) || 0;
    setEquity(maxMortgage - remainingMortgage);
  };

  const formik = useFormik({
    initialValues: {
      homeValue: "",
      remainingMortgage: "",
    },
    validationSchema: helocLimitCalculatorSchema,
    onSubmit: onCalculate,
  });

  return (
    <>
      <div className="bg-neutral-100 p-8 rounded-xl">
        <h2 className="text-3xl lg:text-3xl mb-4">
          Home Equity Line of Credit (HELOC) Limit Calculator
        </h2>
        <div>
          <div className="grid grid-cols-1 lg:grid-cols-7 items-end gap-x-0 mb-4 lg:gap-x-4">
            <NumberInput
              type="number"
              label="Your Home's Value"
              name="homeValue"
              leadingIcon="$"
              value={formik.values.homeValue}
              onChange={(val) => formik.setFieldValue("homeValue", val)}
              errors={formik.errors.homeValue}
              touched={formik.touched.homeValue}
              labelClass="text-cred-100"
              className="bg-transparent"
              parentClass="col-span-3"
            />
            <NumberInput
              type="number"
              label="Remaining Mortgage"
              name="remainingMortgage"
              leadingIcon="$"
              value={formik.values.remainingMortgage}
              onChange={(val) => formik.setFieldValue("remainingMortgage", val)}
              errors={formik.errors.remainingMortgage}
              touched={formik.touched.remainingMortgage}
              labelClass="text-cred-100"
              className="bg-transparent"
              parentClass="col-span-3"
            />
            <div className="mb-3">
              <Button
                variant="primary"
                type="submit"
                onClick={formik.handleSubmit}
              >
                Calculate
              </Button>
            </div>
          </div>
        </div>
      </div>
      {equity !== undefined && (
        <div className="bg-neutral-100 p-8 rounded-xl mt-5">
          <p className="text-2xl font-semibold mb-3">
            HELOC Limit:{" "}
            <span className="text-cred-100">
              ${equity.toLocaleString("en-US", { maximumFractionDigits: 2 })}
            </span>
          </p>
          <p>
            You can borrow up to $200,000 based on your home's value of
            $500,000. That gives a maximum combined loan-to-value (CLTV) ratio
            of 80%.
          </p>
        </div>
      )}
    </>
  );
}
