import React from "react";
import { Button, RadioCard } from "../common";

type stepProps = {
  formValues: any;
  onNextStep: () => void;
  onBackStep: () => void;
  updateFormValues: (key: string, value: any) => void;
};

export default function ApplyStepThree({
  formValues,
  onNextStep,
  onBackStep,
  updateFormValues,
}: stepProps) {
  const [radioValue, setRadioValue] = React.useState(
    formValues?.fundRequired ?? ""
  );
  const [error, setError] = React.useState("");
  const onSelectedChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setRadioValue(e.target.value);
  };
  const inputs = [
    {
      label: "Within 45 Days",
      value: "wihtin-45-days",
      inputName: "fund-required",
    },
    {
      label: "Within 20 Days",
      value: "within-20-days",
      inputName: "fund-required",
    },
    {
      label: "Unsure",
      value: "unsure",
      inputName: "fund-required",
    },
  ];

  const onSubmitStep = () => {
    if (radioValue === "") {
      setError("Select a duration!");
    } else {
      updateFormValues("fundRequired", radioValue);
      onNextStep();
    }
  };

  return (
    <div className="flex flex-col gap-3 mb-4 text-lg">
      <h2 className="text-3xl font-bold text-center mb-4">
        Mortgage Information
      </h2>
      <p className="font-bold">Select Funds Required</p>
      {inputs.map((item, i) => {
        return (
          <RadioCard
            key={i}
            label={item.label}
            value={item.value}
            inputName={item.inputName}
            selectedValue={radioValue}
            setSelectedValue={onSelectedChange}
          />
        );
      })}
      <p className="text-red-500">{error}</p>
      <div className="flex justify-end gap-3">
        <Button
          type="button"
          variant="secondary"
          onClick={() => {
            updateFormValues("fundRequired", radioValue);
            onBackStep();
          }}
        >
          Back
        </Button>
        <Button type="button" onClick={onSubmitStep}>
          Next
        </Button>
      </div>
    </div>
  );
}
