import React from "react";
import { Fade } from "react-awesome-reveal";
import { BsChevronRight } from "react-icons/bs";
import { FaChevronRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";

interface BreadCrumbItem {
  title: React.ReactNode | string;
  link: string;
}

interface TitleProps {
  title: String;
  bg: String;
  date?: String;
  breadCrumbs?: BreadCrumbItem[];
}

const TitleSection: React.FC<TitleProps> = ({
  title,
  bg,
  date = "",
  breadCrumbs,
}) => {
  return (
    <div
      className="h-96 bg-cover bg-center bg-no-repeat relative"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="absolute inset-0 w-full h-full pt-20">
        <div className="container mx-auto h-full px-6 py-14">
          <div className="flex flex-col h-full w-full justify-between">
            <Fade direction="left" triggerOnce>
              <div className="flex flex-wrap items-center gap-1 text-white">
                {breadCrumbs &&
                  breadCrumbs.map((item, i) => {
                    if (i < breadCrumbs.length - 1) {
                      return (
                        <div key={i} className="flex items-center gap-1">
                          <NavLink
                            to={item.link}
                            className={i === 0 ? "text-lg" : "text-sm"}
                          >
                            {item.title}
                          </NavLink>
                          <FaChevronRight fontSize="12px" />
                        </div>
                      );
                    } else {
                      return (
                        <NavLink key={i} to={item.link} className="text-sm">
                          {item.title}
                        </NavLink>
                      );
                    }
                  })}
              </div>
            </Fade>
            <Fade direction="left" triggerOnce>
              <div>
                <h2 className="text-5xl text-white mb-2">{title}</h2>
                <p className="text-xl text-white">
                  Where Every Mortgage Leads to Your Happy Home
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleSection;
