import { HiOutlineRefresh } from "react-icons/hi";
import { RiExchangeDollarLine, RiStarSmileLine } from "react-icons/ri";
import { Fade } from "react-awesome-reveal";

interface TypeCardProps {
  title: string;
  icon: React.ReactNode;
}

const TypeCard: React.FC<TypeCardProps> = ({ title, icon }) => (
  <div className="overflow-hidden flex flex-col items-center justify-center text-center">
    <div className="bg-cred-100/10 p-4 rounded-full text-4xl mb-4 text-cred-100">
      {icon}
    </div>
    <h2 className="text-xl font-bold mb-4">{title}</h2>
  </div>
);

const ResidentialTypes = () => {
  return (
    <div className="bg-zinc-50 py-10">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 items-center">
          <div className="border-0 lg:border-r border-gray-300 py-6">
            <Fade direction="left" triggerOnce>
              <h3 className="text-3xl font-bold text-center lg:text-start">
                Residential
                <br />
                Solutions For
              </h3>
            </Fade>
          </div>
          <Fade direction="up" triggerOnce>
            <TypeCard title="First Time" icon={<RiStarSmileLine />} />
            <TypeCard title="Refinance" icon={<RiExchangeDollarLine />} />
            <TypeCard title="Renewal" icon={<HiOutlineRefresh />} />
          </Fade>
          <div />
        </div>
      </div>
    </div>
  );
};

export default ResidentialTypes;
