import { Fade } from "react-awesome-reveal";
import GetInTouchSection from "../GetInTouchSection";
import { useState } from "react";
import { BsChevronRight } from "react-icons/bs";

type faqObj = {
  question: string;
  answer: () => JSX.Element;
};

const FaqItem = ({ question, answer }: faqObj) => {
  const [isAnswerVisible, setIsAnswerVisible] = useState(false);

  const toggleAnswerVisibility = () => {
    setIsAnswerVisible(!isAnswerVisible);
  };

  return (
    <Fade direction="up" delay={300} triggerOnce>
      <div className="border-b py-3">
        <div
          className="flex items-center gap-2 w-full cursor-pointer"
          onClick={toggleAnswerVisibility}
        >
          <BsChevronRight
            className={`text-cred-100 ${
              isAnswerVisible ? "rotate-90" : "rotate-0"
            }`}
          />
          <p className="text-lg font-semibold w-full">{question}</p>
        </div>
        {!isAnswerVisible ? <></> : answer()}
      </div>
    </Fade>
  );
};

export default function BridgeFinancingScreen() {
  const [faqs] = useState<faqObj[]>([
    {
      question: "What is Bridge Financing?",
      answer: () => {
        return (
          <div className="px-6 py-3">
            <p>
              Bridge financing, also known as a bridge loan, is a short-term
              financial solution designed to provide immediate capital to cover
              temporary funding gaps. It acts as a "bridge" between two
              financial situations, such as purchasing a new property before
              selling an existing one or securing short-term capital while
              awaiting long-term financing.
            </p>
          </div>
        );
      },
    },
    {
      question: "Why Consider Bridge Financing?",
      answer: () => {
        return (
          <div className="px-6 py-3">
            <p>
              Bridge financing is often utilized in scenarios where quick access
              to funds is critical. For example, real estate investors may use
              bridge loans to secure a new property while waiting for their
              current property to sell. Similarly, businesses might rely on
              bridge financing to cover operational costs or seize opportunities
              while awaiting a larger, more permanent source of capital.
            </p>
          </div>
        );
      },
    },
    {
      question: "Key Features of Bridge Financing",
      answer: () => {
        return (
          <div className="px-6 py-3">
            <p>
              <ul>
                <li className="mx-4">
                  <b>⦿ Short-Term Solution:</b> Typically ranging from a few
                  months to a year, bridge loans are designed to be repaid
                  quickly once the borrower secures longer-term financing or
                  completes the sale of an asset.
                </li>
                <li className="mx-4">
                  <b>⦿ Flexible Terms:</b> Bridge loans offer flexible terms and
                  can be tailored to meet specific needs, providing the
                  necessary funds without the lengthy approval processes
                  associated with traditional loans.
                </li>
                <li className="mx-4">
                  <b>⦿ Higher Interest Rates:</b> Due to the short-term nature
                  and the associated risks, bridge financing often comes with
                  higher interest rates compared to conventional loans. However,
                  it provides the necessary liquidity to capitalize on immediate
                  opportunities.
                </li>
                <li className="mx-4">
                  <b>⦿ Secured and Unsecured Options:</b> Depending on the
                  lender and the borrower's financial situation, bridge
                  financing can be either secured by an asset (such as real
                  estate) or unsecured.
                </li>
              </ul>
            </p>
          </div>
        );
      },
    },
    {
      question: "How Bridge Financing Works",
      answer: () => {
        return (
          <div className="px-6 py-3">
            <p>
              <ul>
                <li className="mx-4">
                  <b>⦿ Application:</b> The borrower applies for a bridge loan,
                  providing details about their financial needs and the asset or
                  opportunity requiring funding.
                </li>
                <li className="mx-4">
                  <b>⦿ Approval and Funding:</b> Upon approval, the lender
                  provides the bridge loan amount, which is typically disbursed
                  quickly to address the borrowerâ€™s immediate financial
                  requirements.
                </li>
                <li className="mx-4">
                  <b>⦿ Repayment:</b> The bridge loan is repaid once the
                  borrower secures long-term financing or sells the asset.
                  Repayment terms include both the principal amount and
                  interest.
                </li>
              </ul>
            </p>
          </div>
        );
      },
    },
    {
      question: "When to Use Bridge Financing",
      answer: () => {
        return (
          <div className="px-6 py-3">
            <p>
              <ul>
                <li className="mx-4">
                  <b>⦿ Real Estate Transactions:</b> When purchasing a new
                  property before selling an existing one.
                </li>
                <li className="mx-4">
                  <b>⦿ Business Capital Needs:</b> To cover short-term financial
                  needs or seize immediate opportunities.
                </li>
                <li className="mx-4">
                  <b>⦿ Project Funding:</b> To fund projects or investments
                  while awaiting longer-term capital.
                </li>
              </ul>
            </p>
          </div>
        );
      },
    },
  ]);

  return (
    <>
      <section className="px-6 py-16 lg:py-24">
        <div className="container mx-auto">
          <Fade direction="up" triggerOnce>
            <h2 className="text-4xl lg:text-5xl mb-4">Bridge Financing</h2>
            <p className="mb-10">
              Transition smoothly between homes with our bridge financing. We'll
              provide the short-term funds you need while your current home is
              being sold.
              <br />
              <br />
              Seize your next property before selling your current one with our
              bridge loans. We provide the necessary funds to bridge the gap,
              offering short-term financing solutions that align with your
              timelines, ensuring you never miss out on a property opportunity.
            </p>
          </Fade>
          <Fade direction="up" triggerOnce>
            <div className="bg-neutral-100 p-8 rounded-xl mb-10">
              <h2 className="text-3xl mb-4">
                Introduction to Bridge Financing
              </h2>
              {faqs.map((item, i) => {
                return (
                  <FaqItem
                    key={i}
                    question={item.question}
                    answer={item.answer}
                  />
                );
              })}
            </div>
            <p>
              Bridge financing can be a powerful tool to manage cash flow and
              capitalize on timely opportunities. If you're considering bridge
              financing, consult with a financial advisor to determine if it's
              the right solution for your specific needs.
              <br />
              <br />
              For more information on bridge financing and how it can benefit
              you, contact us today!
            </p>
          </Fade>
        </div>
      </section>
      <GetInTouchSection />
    </>
  );
}
