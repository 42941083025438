import { CommercialScreen, TitleSection } from "../components";
import { DefaultLayout } from "../layouts";

export default function CommercialPage() {
  return (
    <DefaultLayout>
      <TitleSection
        title="Commercial Mortgage"
        bg="/images/commercial-bg.jpg"
      />
      <CommercialScreen />
    </DefaultLayout>
  );
}
