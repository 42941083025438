export const CalculatorData = [
  {
    term: "6 Month - Fixed",
    ratesFrom: "3.99",
    payment: "0",
    ratesFromText:
      "Our Rate Relief product is the lowest mortgage rate available in Canada. Get 6 months of lower payments when you need cash for closing costs and moving-related expenses. After 6 months, renew into a great, longer-term rate. If you choose NOT to renew, a 1% admin fee will apply. For new purchases and switches, and for home values below $1M (unless original mortgage default insurance is still valid).",
    ratesUpto: "4.25",
    year:'0.5',
  },
  {
    term: "1 Year - Fixed",
    ratesFrom: "5.99",
    payment: "0",
    ratesFromText:
      "This great rate is available for High Ratio purchases only.  Offers 20% lump sum payments per year and the ability to increase your payments by up to 20% as well!  It’s a full frills mortgage with a rate that can be held for up to 120 days. ",
    ratesUpto: "6.24",
    year:'1',
  },
  {
    term: "2 Year - Fixed",
    ratesFrom: "5.39",
    payment: "0",
    ratesFromText:
      "This great rate is available for High Ratio Purchases only.  Offers 20% lump sum payments per year and the ability to increase your payments by up to 20% as well!  It’s a full frills mortgage with a rate that can be held for up to 120 days. ",
    ratesUpto: "6.19",
    year:'2',
  },
  {
    term: "3 Year - Fixed",
    ratesFrom: "4.74",
    payment: "0",
    ratesFromText:
      "This great rate is available for High Ratio Mortgages only and can be offered for new purchases only.  Offers 20% lump sum payments per year and the ability to increase your payments by up to 20% as well!  It’s a full frills mortgage and your application can fund within 120 days.  ",
    ratesUpto: "5.84",
    year:'3',
  },
  {
    term: "4 Year - Fixed",
    ratesFrom: "4.54",
    payment: "0",
    ratesFromText:
      "This great rate is available for High Ratio Mortgages only and can be offered for both new purchases and renewals.  Offers 20% lump sum payments per year and the ability to increase your payments by up to 20% as well!  It’s a full frills mortgage with a rate that can be held for up to 120 days. ",
    ratesUpto: "5.84",
    year:'4',
  },
  {
    term: "5 Year - Fixed",
    ratesFrom: "4.44",
    payment: "0",
    ratesFromText:
      "This great rate is available for High Ratio Mortgages only and can be offered for new purchases only.  Offers the ability to make extra payments and/or increase your monthly payments by up to 20% per year!  Best yet, this product is full frills, meaning there are NO funny restrictions such as a bona-fide sales clause!  Mortgage must fund within 40 days of approval. Rate is available for new submissions only.",
    ratesUpto: "5.84",
    year:'5',
  },
  {
    term: "5 Year - Variable",
    ratesFrom: "5.50",
    payment: "0",
    ratesFromText:
      "Although past performance does not predict the future, historically variable rates have cost clients less overall. With a rate this absurdly low it's easy to see how this could be the case!<br/>This great rate is available for High Ratio Mortgages only and can be offered for both new purchases and some switches. Rate guaranteed at Prime -1.20% for your term and offers the ability to make extra payments and/or increase your monthly payments by up to 20% per year!  Best yet, this product is full frills, meaning there are NO funny restrictions such as a bona-fide sales clause!<br/>Based on Prime at 6.70%",
    ratesUpto: "6.60",
    year:'5',
  },
  {
    term: "HELOC - Open",
    ratesFrom: "6.00",
    payment: "0",
    ratesFromText:
      "HELOC or Home Equity Line of Credit can be a great tool for many people who want to use the equity in their home for a quick and low rate borrowing option. Based on Prime at 6.70%.",
    ratesUpto: "7.20",
    year:'30',
  },
];
