import { useFormik } from "formik";
import { useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { affordabilityCalculatorSchema } from "../../../utils/schemas";
import { Button, CSelect, NumberInput } from "../../common";
import { ontarioCities } from "../../../utils/ontarioCities";

export default function AffordabilityCalculator() {
  const [showDropdown, setShowDropdown] = useState(true);
  const locations = ontarioCities.map((item) => ({ label: item, value: item }));
  const [backendRatio, setBackendRatio] = useState(0);
  const [frontendRatio, setFrontendRatio] = useState(0);
  const [message, setMessage] = useState("");

  const onCalculate = () => {
    let card = parseInt(formik.values.creditCardDebtPayment) || 0;
    let car = parseInt(formik.values.carPayment) || 0;
    let other = formik.values.otherLoanExpenses || 0;
    let mortgage = parseInt(formik.values.desiredMortgagePayment) || 0;
    let income = parseInt(formik.values.income) || 0;
    let coincome = parseInt(formik.values.coapplicantIncome) || 0;

    let totalDebts = card + car + other + mortgage;
    let grossIncome = income + coincome;

    let backendRatio = (totalDebts / grossIncome) * 100;
    let frontendRatio = (mortgage / grossIncome) * 100;
    setBackendRatio(backendRatio);
    setFrontendRatio(frontendRatio);
    if (backendRatio <= 43 && frontendRatio <= 30) {
      setMessage("You can afford to have the desired loan.");
    } else {
      setMessage("Sorry, you cannot afford to have the desired loan.");
    }
  };

  const formik = useFormik({
    initialValues: {
      desiredMortgagePayment: "",
      income: "",
      coapplicantIncome: "",
      downPayment: "",
      futureHomeLocation: locations[0].value,
      annualPropertyTax: "",
      monthlyCondoFees: "",
      monthlyHeatingCosts: "",
      creditCardDebtPayment: "",
      carPayment: "",
      otherLoanExpenses: 0,
    },
    validationSchema: affordabilityCalculatorSchema,
    onSubmit: onCalculate,
  });

  return (
    <>
      <div className="bg-neutral-100 p-8 rounded-xl">
        <h2 className="text-3xl lg:text-3xl mb-4">
          Mortgage Affordability Calculator
        </h2>
        <p>
          When searching for a new home, the first step is to figure out how
          much you can afford. Ratehub.ca takes the most important factors like
          your income and expenses and determines the maximum purchase price
          that you can qualify for with our mortgage affordability calculator.
        </p>
        <div
          className="flex justify-between items-center my-3 p-2 rounded-lg cursor-pointer hover:bg-neutral-200"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <p className="text-xl font-semibold">1. Your maximum home price</p>
          <div
            className={`bg-cred-100 text-white rounded-full p-2 ${
              showDropdown ? "rotate-180" : "rotate-0"
            }`}
          >
            <BsChevronDown />
          </div>
        </div>
        {showDropdown && (
          <div>
            <p className="text-lg font-semibold">Annual income</p>
            <p>
              Your gross income before-tax, including any bonuses and
              supplementary income.
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-0 mb-4 lg:gap-x-4">
              <NumberInput
                type="number"
                label="Your Income"
                name="income"
                leadingIcon="$"
                value={formik.values.income}
                onChange={(val) => formik.setFieldValue("income", val)}
                errors={formik.errors.income}
                touched={formik.touched.income}
                labelClass="text-cred-100"
                className="bg-transparent"
              />
              <NumberInput
                type="number"
                label="Co-applicant's Income"
                name="coapplicantIncome"
                leadingIcon="$"
                value={formik.values.coapplicantIncome}
                onChange={(val) =>
                  formik.setFieldValue("coapplicantIncome", val)
                }
                errors={formik.errors.coapplicantIncome}
                touched={formik.touched.coapplicantIncome}
                labelClass="text-cred-100"
                className="bg-transparent"
              />
              <NumberInput
                type="number"
                label="Desired Mortgage Payment"
                name="desiredMortgagePayment"
                leadingIcon="$"
                value={formik.values.desiredMortgagePayment}
                onChange={(val) =>
                  formik.setFieldValue("desiredMortgagePayment", val)
                }
                errors={formik.errors.desiredMortgagePayment}
                touched={formik.touched.desiredMortgagePayment}
                labelClass="text-cred-100"
                className="bg-transparent"
              />
              <NumberInput
                type="number"
                label="Down Payment"
                name="downPayment"
                leadingIcon="$"
                value={formik.values.downPayment}
                onChange={(val) => formik.setFieldValue("downPayment", val)}
                errors={formik.errors.downPayment}
                touched={formik.touched.downPayment}
                labelClass="text-cred-100"
                className="bg-transparent"
              />
              <CSelect
                label="Location of your future home"
                name="futureHomeLocation"
                placeholder="Select City"
                options={locations}
                onChange={(e) =>
                  formik.setFieldValue("futureHomeLocation", e.target.value)
                }
                errors={formik.errors.futureHomeLocation}
                touched={formik.touched.futureHomeLocation}
                labelClass="text-cred-100"
                className="bg-transparent"
              />
            </div>
            <p className="text-lg font-semibold">
              Living costs of your future home
            </p>
            <p>
              If you don't know these costs, leave the fields blank and we will
              estimate for you.
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-0 mb-4 lg:gap-x-4">
              <NumberInput
                type="number"
                label="Annual Property Tax"
                name="annualPropertyTax"
                leadingIcon="$"
                value={formik.values.annualPropertyTax}
                onChange={(val) =>
                  formik.setFieldValue("annualPropertyTax", val)
                }
                errors={formik.errors.annualPropertyTax}
                touched={formik.touched.annualPropertyTax}
                labelClass="text-cred-100"
                className="bg-transparent"
              />
              <NumberInput
                type="number"
                label="Monthly Condo Fees"
                name="monthlyCondoFees"
                leadingIcon="$"
                value={formik.values.monthlyCondoFees}
                onChange={(val) =>
                  formik.setFieldValue("monthlyCondoFees", val)
                }
                errors={formik.errors.monthlyCondoFees}
                touched={formik.touched.monthlyCondoFees}
                labelClass="text-cred-100"
                className="bg-transparent"
              />
              <NumberInput
                type="number"
                label="Monthly Heating Costs"
                name="monthlyHeatingCosts"
                leadingIcon="$"
                value={formik.values.monthlyHeatingCosts}
                onChange={(val) =>
                  formik.setFieldValue("monthlyHeatingCosts", val)
                }
                errors={formik.errors.monthlyHeatingCosts}
                touched={formik.touched.monthlyHeatingCosts}
                labelClass="text-cred-100"
                className="bg-transparent"
              />
            </div>
            <p className="text-lg font-semibold">Debt payments</p>
            <p>
              Enter debt payments if applicable. If you have none, you can leave
              blank.
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-0 mb-4 lg:gap-x-4">
              <NumberInput
                type="number"
                label="Credit Card Debt Payment"
                name="creditCardDebtPayment"
                leadingIcon="$"
                value={formik.values.creditCardDebtPayment}
                onChange={(val) =>
                  formik.setFieldValue("creditCardDebtPayment", val)
                }
                errors={formik.errors.creditCardDebtPayment}
                touched={formik.touched.creditCardDebtPayment}
                labelClass="text-cred-100"
                className="bg-transparent"
              />
              <NumberInput
                type="number"
                label="Car Payment"
                name="carPayment"
                leadingIcon="$"
                value={formik.values.carPayment}
                onChange={(val) => formik.setFieldValue("carPayment", val)}
                errors={formik.errors.carPayment}
                touched={formik.touched.carPayment}
                labelClass="text-cred-100"
                className="bg-transparent"
              />
              <NumberInput
                type="number"
                label="Other Loan Expenses"
                name="otherLoanExpenses"
                leadingIcon="$"
                value={formik.values.otherLoanExpenses}
                onChange={(val) =>
                  formik.setFieldValue("otherLoanExpenses", val)
                }
                errors={formik.errors.otherLoanExpenses}
                touched={formik.touched.otherLoanExpenses}
                labelClass="text-cred-100"
                className="bg-transparent"
              />
            </div>
            <div className="flex items-center gap-3">
              <Button
                variant="primary"
                type="submit"
                onClick={formik.handleSubmit}
              >
                Calculate
              </Button>
              <Button
                variant="transparent-secondary"
                onClick={() => {
                  formik.resetForm();
                  setBackendRatio(0);
                  setFrontendRatio(0);
                  setMessage("");
                }}
              >
                Clear
              </Button>
            </div>
          </div>
        )}
      </div>
      {message !== "" && (
        <div className="bg-neutral-100 p-8 rounded-xl mt-5">
          <p className="text-2xl font-semibold mb-2">
            Backend Ratio:{" "}
            <span className="text-cred-100">
              {backendRatio.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
              %
            </span>
          </p>
          <p className="text-2xl font-semibold mb-2">
            Frontend Ratio:{" "}
            <span className="text-cred-100">
              {frontendRatio.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
              %
            </span>
          </p>
          <p className="text-2xl font-semibold">{message}</p>
        </div>
      )}
    </>
  );
}
