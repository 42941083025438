import axios, { BACKEND_URL } from "../axios";

class AgentInquiryModel {
  static endPoint = "agent-inquiry/";

  static registerInquiry(body: any) {
    return axios.post(BACKEND_URL + this.endPoint + "agentInquiryRegister", body);
  }
}

export default AgentInquiryModel;
