import React from "react";
import Popup from "reactjs-popup";
import { HiX } from "react-icons/hi";

interface ModalProps {
  title: string;
  children: React.ReactNode;
  open: boolean;
  toggleModal: () => void;
}

const Modal: React.FC<ModalProps> = ({
  title,
  children,
  open,
  toggleModal,
}) => {
  return (
    <Popup open={open} closeOnDocumentClick>
      <div className="px-3 py-2">
        <div className="flex items-center justify-between border-b pb-2">
          <h2 className="text-xl font-semibold">{title}</h2>
          <button onClick={toggleModal}>
            <HiX size={20} />
          </button>
        </div>
        <div className="max-h-[90vh] overflow-y-auto py-3">{children}</div>
      </div>
    </Popup>
  );
};

export default Modal;
