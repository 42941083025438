export const ontarioCities = [
    "Toronto",
    "Ottawa",
    "Mississauga",
    "Brampton",
    "Hamilton",
    "London",
    "Markham",
    "Vaughan",
    "Kitchener",
    "Windsor",
    "Richmond Hill",
    "Oakville",
    "Burlington",
    "Guelph",
    "Thunder Bay",
    "Sudbury",
    "Kingston",
    "Peterborough",
    "Sault Ste. Marie",
    "Brantford",
    "St. Catharines",
    "Niagara Falls",
    "Cambridge",
    "Chatham-Kent",
    "Oshawa",
    "Scarborough",
    "Aurora",
    "Newmarket",
    "Milton",
    "Collingwood",
    "North Bay",
    "Stratford",
    "Welland",
    "Belleville",
    "Orillia",
    "Sarnia",
    "Orangeville",
    "Georgina",
    "Innisfil",
    "Wasaga Beach",
    "Port Colborne",
    "Clarington",
    "Fort Erie",
    "Huntsville",
    "Timmins",
    "Kenora"
];