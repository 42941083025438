import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";

interface myProps {
  question: String | "";
  answer: () => JSX.Element;
}

const Faqs: React.FC<myProps> = ({ question, answer }) => {
  const [isAnswerVisible, setIsAnswerVisible] = useState(false);

  const toggleAnswerVisibility = () => {
    setIsAnswerVisible(!isAnswerVisible);
  };

  return (
    // <div className="container mx-auto px-6 py-16">

    <Fade direction="up" delay={300} triggerOnce>
      <div className="grid grid-cols-1 lg:grid-cols-1 gap-8 rounded-lg p-1 w-full lg:w-4/5 mx-auto bg-slate-80">
        <div className="md:w-auto bg-slate-80 rounded-md p-4 justify-content bt-2">
          <h6
            className="text-lg font-semibold cursor-pointer"
            onClick={toggleAnswerVisibility}
          >
            {question}
          </h6>
          <p className="mx-4">{!isAnswerVisible ? <></> : answer()}</p>
        </div>
      </div>
    </Fade>
    // </div>
  );
};

export default Faqs;
