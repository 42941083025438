import { MdHomeFilled } from "react-icons/md";
import { DefaultLayout } from "../../layouts";
import { BridgeFinancingScreen, TitleSection } from "../../components";

export default function BridgeFinancing() {
  const breadCrumbs = [
    { title: <MdHomeFilled />, link: "/" },
    { title: "Services", link: "#" },
    { title: "Bridge Financing", link: "#" },
  ];

  return (
    <DefaultLayout>
      <TitleSection
        title="Services"
        bg="/images/bad-credit-loan-bg.png"
        breadCrumbs={breadCrumbs}
      />
      <BridgeFinancingScreen />
    </DefaultLayout>
  );
}
