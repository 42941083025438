import React from "react";
import { ApplyFormModel } from "../../../service";
import { toast } from "react-toastify";
import ApplyStepOne from "./ApplyStepOne";
import ApplyStepTwo from "./ApplyStepTwo";
import { Fade } from "react-awesome-reveal";

type formObject = {
  [key: string]: string;
};

export default function ApplyForm() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [formValues, setFormValues] = React.useState({});

  const onNextStep = () => setActiveStep(activeStep + 1);
  const onBackStep = () => setActiveStep(activeStep - 1);

  const updateFormValues = (values: any) => {
    setFormValues(values);
  };

  const onFormSubmit = async (values: any) => {
    let form: formObject = { ...formValues };
    form.fullName = values.fullName;
    form.email = values.email;
    form.phone = values.phone;
    form.province = values.province;
    form.specialist = values.specialist === "" ? "N/A" : values.specialist;
    try {
      let response = await ApplyFormModel.register(form);
      if (response.status === 200) {
        toast.success("Application sent successfully!");
        setActiveStep(0);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  const steps = [
    <ApplyStepOne
      onNextStep={onNextStep}
      updateFormValues={updateFormValues}
    />,
    <ApplyStepTwo onBackStep={onBackStep} onFormSubmit={onFormSubmit} />,
  ];
  return (
    <section className="bg-neutral-900">
      <Fade direction="up" triggerOnce>
        <div className="container mx-auto px-6 py-16 lg:py-24">
          <h2 className="text-4xl lg:text-5xl text-white mb-3">
            Get Your Best Rates
          </h2>
          <p className="text-white mb-5">
            Fill out some information and one of our expert brokers will be in
            touch with you soon.
          </p>
          <div className="grid grid-cols-1 lg:grid-cols-3 bg-white rounded-lg">
            {steps[activeStep]}
            <div
              className="bg-cover bg-center"
              style={{ backgroundImage: "url(/images/apply-form-img.png)" }}
            ></div>
          </div>
        </div>
      </Fade>
    </section>
  );
}
