import React from "react";

export default function WhatsAppIcon() {
  return (
    <div className="fixed bottom-[40px] right-2 hover:scale-105 md:bottom-[85px]">
      <a
        href="https://wa.me/16472725221"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/images/whatsapp-icon.png"
          className="w-12"
          alt="WhatsApp Chat"
        />
      </a>
    </div>
  );
}
