import { Fade } from "react-awesome-reveal";
import GetInTouchSection from "../GetInTouchSection";

export default function BusinessLoanScreen() {
  return (
    <>
      <section className="px-6 py-16 lg:py-24">
        <div className="container mx-auto">
          <Fade direction="up" triggerOnce>
            <h2 className="text-4xl lg:text-5xl mb-4">Business Loan</h2>
            <p className="mb-10">
              Fuel your business growth with our tailored loans. Get the capital
              you need for your business endeavours with our strategic financial
              advice.
              <br />
              <br />
              Our Business Loans are not just about providing capital; they're
              about creating partnerships. We offer various loan types, from SBA
              loans to commercial lines of credit, ensuring you have the funds
              to grow, innovate, and lead in your market.
            </p>
          </Fade>
        </div>
      </section>
      <GetInTouchSection />
    </>
  );
}
