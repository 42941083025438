import { Fade } from "react-awesome-reveal";
import GetInTouchSection from "../GetInTouchSection";

export default function InvestmentPropertyScreen() {
  return (
    <>
      <section className="px-6 py-16 lg:py-24">
        <div className="container mx-auto">
          <Fade direction="up" triggerOnce>
            <h2 className="text-4xl lg:text-5xl mb-4">Investment Property</h2>
            <p className="mb-10">
              Invest with intelligence. Leverage our expertise to find the right
              financing for your investment properties, maximizing your returns.
              <br />
              <br />
              Maximize your real estate investment with our specialized mortgage
              products. We understand the unique challenges and opportunities of
              investment properties and offer custom solutions, including
              interest-only loans and adjustable-rate mortgages, to help you
              achieve your long-term financial goals.
            </p>
          </Fade>
        </div>
      </section>
      <GetInTouchSection />
    </>
  );
}
