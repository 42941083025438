import { MdHomeFilled } from "react-icons/md";
import { BlogScreen, TitleSection } from "../components";
import { DefaultLayout } from "../layouts";

export default function BlogPage() {
  const breadCrumbs = [
    { title: <MdHomeFilled />, link: "/" },
    { title: "Blogs", link: "#" },
  ];

  return (
    <DefaultLayout footerVariant="light">
      <TitleSection
        title="Blogs"
        bg="/images/news-and-blogs-bg.png"
        breadCrumbs={breadCrumbs}
      />
      <BlogScreen />
    </DefaultLayout>
  );
}
