import { Fade } from "react-awesome-reveal";
import GetInTouchSection from "../GetInTouchSection";
import RefinanceCalculator from "./RefinanceCalculator";

export default function RefinanceScreen() {
  return (
    <>
      <section className="px-6 py-16 lg:py-24">
        <div className="container mx-auto">
          <Fade direction="up" triggerOnce>
            <h2 className="text-4xl lg:text-5xl mb-4">Refinance</h2>
            <p className="mb-10">
              Your first home purchase should be exciting, not overwhelming.
              We're here to guide you through every step, ensuring a memorable
              and stress-free experience.
              <br />
              <br />
              Our First Home program is designed to make your dream of
              homeownership a reality.
              <br />
              <br />
              From understanding mortgages to selecting the right one, we
              provide step-by-step guidance, special first-time buyer rates, and
              resources to help you find government grants and incentives.
            </p>
          </Fade>
          <Fade direction="up" triggerOnce>
            <RefinanceCalculator />
          </Fade>
        </div>
      </section>
      <GetInTouchSection />
    </>
  );
}
