import axios, { BACKEND_URL } from "../axios";

class PagesModel {
  static endPoint = "pages";

  static get(type: string) {
    return axios.get(BACKEND_URL + this.endPoint + `/${type}` );
  }
}

export default PagesModel;
