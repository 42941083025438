import { Fade } from "react-awesome-reveal";
import GetInTouchSection from "../GetInTouchSection";
import DebtConsolidationCalculator from "./DebtConsolidationCalculator";

export default function DebtConsolidationScreen() {
  return (
    <>
      <section className="px-6 py-16 lg:py-24">
        <div className="container mx-auto">
          <Fade direction="up" triggerOnce>
            <h2 className="text-4xl lg:text-5xl mb-4">Debt Consolidation</h2>
            <p className="mb-10">
              Streamline your finances with our debt consolidation options.
              <br />
              <br />
              Combine your debts into one manageable mortgage, reducing your
              interest rates and simplifying your life. Consolidate your
              high-interest debts into one manageable mortgage with our debt
              consolidation services.
              <br />
              <br />
              We'll work with you to assess your debts, discuss how to leverage
              your home equity and create a streamlined plan to reduce your
              overall interest and monthly payments.
            </p>
          </Fade>
          <Fade direction="up" triggerOnce>
            <DebtConsolidationCalculator />
          </Fade>
        </div>
      </section>
      <GetInTouchSection />
    </>
  );
}
