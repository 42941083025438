import { getIn, useFormik } from "formik";
import { debtConsolidationCalculatorSchema } from "../../../utils/schemas";
import { Button, Input, NumberInput } from "../../common";
import { useState } from "react";

export default function DebtConsolidationCalculator() {
  const [currentPayment, setCurrentPayment] = useState<number>();
  const [consolidationPayment, setConsolidationPayment] = useState<number>();

  const onCalculate = () => {
    let currentTotal = formik.values.debts.reduce(
      (sum, val) => sum + parseInt(val.monthlyPayment),
      0
    );
    setCurrentPayment(currentTotal);
    let r = parseInt(formik.values.interestRate) || 0;
    r = r / 100 / 12;
    let pv = parseInt(formik.values.consolidationLoanAmount) || 0;
    let n = parseInt(formik.values.loanTerm) || 0;
    let numerator = r * pv;
    let denominator = 1 - Math.pow(1 + r, -n);
    setConsolidationPayment(numerator / denominator);
  };

  const formik = useFormik({
    initialValues: {
      debts: [
        {
          debtName: "",
          remainingBalance: "",
          monthlyPayment: "",
          interestRate: "",
        },
      ],
      consolidationLoanAmount: "",
      interestRate: "",
      loanTerm: "",
    },
    validationSchema: debtConsolidationCalculatorSchema,
    onSubmit: onCalculate,
  });

  return (
    <>
      <div className="bg-neutral-100 p-8 rounded-xl">
        <h2 className="text-3xl lg:text-3xl mb-4">
          Debt Consolidation Calculator
        </h2>
        <p>
          The Debt Consolidation Calculator can determine whether it is
          financially rewarding to consolidate debts by comparing the APR
          (Annual Percentage Rate) of the combined debts with that of the
          consolidation loan. APR is the fee-adjusted financial cost of a loan,
          providing a more accurate basis for loan comparisons. The calculated
          results will also display comparisons such as the monthly payment,
          payoff length, and total interest.
        </p>
        <div>
          <p className="text-xl font-semibold mt-4">Debts</p>
          {formik.values.debts.map((item, i) => {
            return (
              <div className="grid grid-cols-1 md:grid-cols-5 gap-x-0 md:gap-x-4 border-b border-neutral-300 md:border-b-0">
                <div className="flex gap-3 items-center col-span-1 md:col-span-2">
                  <span className="text-lg mt-[12%] md:mt-[6%]">{i + 1}.</span>
                  <Input
                    type="text"
                    label="Debt name"
                    name={`debts.${i}.debtName`}
                    placeholder="Enter Debt Name"
                    value={item.debtName}
                    onChange={(e) => {
                      let temp = [...formik.values.debts];
                      temp[i].debtName = e.target.value;
                      formik.setFieldValue("debts", temp);
                    }}
                    errors={getIn(formik.errors, `debts[${i}].debtName`)}
                    touched={formik.touched.debts?.[i]?.debtName}
                    labelClass="text-cred-100"
                    className="bg-transparent"
                    parentClass="grow"
                  />
                </div>
                <NumberInput
                  type="number"
                  label="Remaining Balance"
                  name={`debts.${i}.remainingBalance`}
                  leadingIcon="$"
                  value={item.remainingBalance}
                  onChange={(e) => {
                    let temp = [...formik.values.debts];
                    temp[i].remainingBalance = (e || 0).toString();
                    formik.setFieldValue("debts", temp);
                    let sum = temp.reduce(
                      (total, val) => total + parseInt(val.remainingBalance),
                      0
                    );
                    formik.setFieldValue("consolidationLoanAmount", sum);
                  }}
                  errors={getIn(formik.errors, `debts[${i}].remainingBalance`)}
                  touched={formik.touched.debts?.[i]?.remainingBalance}
                  labelClass="text-cred-100"
                  className="bg-transparent"
                  parentClass="col-span-1 ms-5 md:ms-0"
                />
                <NumberInput
                  type="number"
                  label="Monthly or Min Payment"
                  name={`debts.${i}.monthlyPayment`}
                  leadingIcon="$"
                  value={item.monthlyPayment}
                  onChange={(e) => {
                    let temp = [...formik.values.debts];
                    temp[i].monthlyPayment = (e || 0).toString();
                    formik.setFieldValue("debts", temp);
                  }}
                  errors={getIn(formik.errors, `debts[${i}].monthlyPayment`)}
                  touched={formik.touched.debts?.[i]?.monthlyPayment}
                  labelClass="text-cred-100"
                  className="bg-transparent"
                  parentClass="col-span-1 ms-5 md:ms-0"
                />
                <NumberInput
                  type="number"
                  label="Interest Rate"
                  name={`debts.${i}.interestRate`}
                  value={item.interestRate}
                  endingIcon="%"
                  onChange={(e) => {
                    let temp = [...formik.values.debts];
                    temp[i].interestRate = (e || 0).toString();
                    formik.setFieldValue("debts", temp);
                  }}
                  errors={getIn(formik.errors, `debts[${i}].interestRate`)}
                  touched={formik.touched.debts?.[i]?.interestRate}
                  labelClass="text-cred-100"
                  className="bg-transparent"
                  parentClass="col-span-1 ms-5 md:ms-0"
                />
              </div>
            );
          })}
          <div className="text-end">
            <button
              onClick={() => {
                let temp = [...formik.values.debts];
                temp.push({
                  debtName: "",
                  remainingBalance: "",
                  monthlyPayment: "",
                  interestRate: "",
                });
                formik.setFieldValue("debts", temp);
              }}
              className="border-2 border-cred-100 bg-cred-100 text-white text-sm py-2 px-3 rounded-lg font-semibold hover:bg-neutral-900 hover:border-neutral-900"
            >
              Add Row
            </button>
          </div>
          <p className="text-xl font-semibold mt-4">Consolidation Loan</p>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-0 mb-4 lg:gap-x-4">
            <NumberInput
              type="number"
              label="Amount"
              name="consolidationLoanAmount"
              leadingIcon="$"
              value={formik.values.consolidationLoanAmount}
              onChange={(val) => formik.setFieldValue("consolidationLoanAmount", val)}
              errors={formik.errors.consolidationLoanAmount}
              touched={formik.touched.consolidationLoanAmount}
              labelClass="text-cred-100"
              className="bg-transparent"
              readOnly
            />
            <NumberInput
              type="number"
              label="Interest Rate (%)"
              name="interestRate"
              endingIcon="%"
              value={formik.values.interestRate}
              onChange={(val) => formik.setFieldValue("interestRate", val)}
              errors={formik.errors.interestRate}
              touched={formik.touched.interestRate}
              labelClass="text-cred-100"
              className="bg-transparent"
            />
            <NumberInput
              type="number"
              label="Loan Term (Number of months)"
              name="loanTerm"
              placeholder="Months i.e. 12"
              value={formik.values.loanTerm}
              onChange={(val) => formik.setFieldValue("loanTerm", val)}
              errors={formik.errors.loanTerm}
              touched={formik.touched.loanTerm}
              labelClass="text-cred-100"
              className="bg-transparent"
            />
          </div>
        </div>
        <div className="flex items-center gap-3 mt-4">
          <Button variant="primary" type="submit" onClick={formik.handleSubmit}>
            Calculate
          </Button>
          <Button
            variant="transparent-secondary"
            onClick={() => {
              formik.resetForm();
              formik.setFieldValue("debts", [
                {
                  debtName: "",
                  remainingBalance: "",
                  monthlyPayment: "",
                  interestRate: "",
                },
              ]);
              setCurrentPayment(undefined);
              setConsolidationPayment(undefined);
            }}
          >
            Clear
          </Button>
        </div>
      </div>
      {(currentPayment !== undefined || consolidationPayment !== undefined) && (
        <div className="bg-neutral-100 p-8 rounded-xl mt-5">
          <p className="text-2xl font-semibold mb-3">
            Current Total Monthly Payment:{" "}
            <span className="text-cred-100">
              $
              {currentPayment?.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </span>
          </p>
          <p className="text-2xl font-semibold">
            Consolidation Loan Monthly Payment:{" "}
            <span className="text-cred-100">
              $
              {consolidationPayment?.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </span>
          </p>
        </div>
      )}
    </>
  );
}
