import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";

interface colorProps {
  normalBg?: string;
  hoverBg?: string;
  normalText?: string;
  hoverText?: string;
  isBlock?: boolean;
}

const SocialIcons = (props: colorProps) => {
  const { isBlock = false } = props;
  const socialLinks: any = [
    {
      icon: <FaFacebookF />,
      link: "https://www.facebook.com/deltamortgages/",
    },
    {
      icon: <FaTwitter />,
      link: "https://twitter.com/DeltaMortgages?s=08",
    },
    {
      icon: <FaInstagram />,
      link: "https://www.instagram.com/deltamortgagesinc/",
    },
    {
      icon: <FaLinkedinIn />,
      link: "https://www.linkedin.com/in/ahmed-ibrahim-2a9889308?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
  ];
  return (
    <div
      className={`flex items-center  ${
        isBlock ? "flex-col" : "flex-row gap-2"
      }`}
    >
      {socialLinks.map((item: any, i: number) => {
        return (
          <a
            key={i}
            href={item.link}
            className={`${
              isBlock
                ? "p-3 border border-transparent box-border rounded-full my-1"
                : "p-3 rounded-full"
            } ${props.normalText} hover:${props.hoverText} ${
              props.normalBg
            } hover:${props.hoverBg}`}
            style={{ cursor: "pointer" }} // Add cursor property here
          >
            <span className="text-xl">{item.icon}</span>
          </a>
        );
      })}
    </div>
  );
};
export default SocialIcons;
