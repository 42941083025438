import { HelpModel } from "../../service";
import { Fade } from "react-awesome-reveal";
import Button from "../common/Button";
import { Player } from "@lottiefiles/react-lottie-player";
import { useNavigate } from "react-router-dom";

interface myProps {
  model: HelpModel | undefined;
}

const HelpScreen: React.FC<myProps> = ({ model }) => {
  const navigate = useNavigate();

  return (
    <>
      {model == undefined ? (
        <></>
      ) : (
        <div className="container mx-auto px-6 py-16">
          <Fade direction="up" triggerOnce>
            <h2 className="text-4xl font-bold text-center mb-6">
              Best Mortgage Solutions
            </h2>
          </Fade>
          <Fade direction="up" delay={300} triggerOnce>
            <div
              style={{ backgroundColor: "white" }}
              className="grid grid-cols-1 lg:grid-cols-2 gap-8 bg-blue-50 rounded-lg p-8 w-full lg:w-4/5 mx-auto"
            >
              <div className=" gap-3 text-cred-100 mb-6">
                <span className="h-1 w-12 bg-cred-100" />
                <p className="font-bold mb-6">{model.title}</p>
                <p className="text-gray-600 mb-3">{model.subject}</p>
                <Button onClick={() => navigate("/contact")}>
                  Contact Now
                </Button>
              </div>
              {(() => {
                switch (model.title) {
                  case "Construction Loan":
                    return (
                      <Player
                        autoplay
                        loop
                        src="https://lottie.host/2019f355-c89e-4b8b-a1e7-1786506b444a/jxJnZZSxl7.json"
                        style={{ width: "250px", height: "250px" }}
                      />
                    );
                  case "Business Loan":
                    return (
                      <Player
                        autoplay
                        loop
                        src="https://lottie.host/dcfb2f27-5560-4c16-9d59-06ba53f9f281/4nH0vd9zCg.json"
                        style={{ width: "250px", height: "250px" }}
                      />
                    );
                    case "First Home":
                      return (
                        <Player
                          autoplay
                          loop
                          src="https://lottie.host/49870896-819e-42f7-b5d7-342d83ee039d/txRYE74G18.json"
                          style={{ width: "250px", height: "250px" }}
                        />
                      );
                  case "Debt Consolidation":
                    return (
                      <Player
                        autoplay
                        loop
                        src="https://lottie.host/ce2660a1-4885-49ad-a2c2-bc4933260ac5/585zPFZv2S.json"
                        style={{ width: "250px", height: "250px" }}
                      />
                    );
                    case "Investment Property":
                    return (
                      <Player
                        autoplay
                        loop
                        src="https://lottie.host/2af901cc-2ac3-4a4c-acbf-64f181706d69/yWQnA4RgJ9.json"
                        style={{ width: "250px", height: "250px" }}
                      />
                    );
                    case "Bad Credit Loan":
                    return (
                      <Player
                        autoplay
                        loop
                        src="https://lottie.host/84a59c5f-dcc3-4eed-9f6e-ec3b7390a9b3/Vd16zquV02.json"
                        style={{ width: "250px", height: "250px" }}
                      />
                    );
                    case "Rental Property Mortgages":
                    return (
                      <Player
                        autoplay
                        loop
                        src="https://lottie.host/158cf6d8-f746-4300-9a52-9ac4f93f47c5/A371Yt8yLd.json"
                        style={{ width: "250px", height: "250px" }}
                      />
                    );
                  default:
                    return (
                      <Player
                        autoplay
                        loop
                        src="https://assets7.lottiefiles.com/packages/lf20_aEtcq1Z1up.json"
                        style={{ width: "250px", height: "250px" }}
                      />
                    );
                }
              })()}
            </div>
          </Fade>
        </div>
      )}
    </>
  );
};

export default HelpScreen;
