import React from "react";
import { Button, RadioCard } from "../common";

type stepProps = {
  formValues: any;
  onNextStep: () => void;
  updateFormValues: (key: string, value: any) => void;
};

export default function ApplyStepOne({
  formValues,
  onNextStep,
  updateFormValues,
}: stepProps) {
  const [radioValue, setRadioValue] = React.useState(
    formValues?.mortgageType ?? ""
  );
  const [error, setError] = React.useState("");
  const onSelectedChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setRadioValue(e.target.value);
  };
  const inputs = [
    {
      label: "Pre-Qualify",
      value: "pre-qualify",
      inputName: "mortgage-type",
    },
    {
      label: "Purchase",
      value: "purchase",
      inputName: "mortgage-type",
    },
    {
      label: "Refinance",
      value: "refinance",
      inputName: "mortgage-type",
    },
    {
      label: "Renew/Switch",
      value: "renew-switch",
      inputName: "mortgage-type",
    },
  ];

  const onSubmitStep = () => {
    if (radioValue === "") {
      setError("Select a type!");
    } else {
      updateFormValues("mortgageType", radioValue);
      onNextStep();
    }
  };

  return (
    <div className="flex flex-col gap-3 mb-4 text-lg">
      <h2 className="text-3xl font-bold text-center mb-4">
        Mortgage Information
      </h2>
      <p className="font-bold">Select Mortgage Type</p>
      {inputs.map((item, i) => {
        return (
          <RadioCard
            key={i}
            label={item.label}
            value={item.value}
            inputName={item.inputName}
            selectedValue={radioValue}
            setSelectedValue={onSelectedChange}
          />
        );
      })}
      <p className="text-red-500">{error}</p>
      <div className="flex justify-end gap-3">
        <Button type="button" onClick={onSubmitStep}>
          Next
        </Button>
      </div>
    </div>
  );
}
