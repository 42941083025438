import { Fade } from "react-awesome-reveal";
import GetInTouchSection from "../GetInTouchSection";

export default function ResidentialMortgageScreen() {
  return (
    <>
      <section className="px-6 py-16 lg:py-24">
        <div className="container mx-auto">
          <Fade direction="up" triggerOnce>
            <h2 className="text-4xl lg:text-5xl mb-4">Residential Mortgage</h2>
            <p className="mb-10">
              The majority of individuals, if not all, may find it challenging
              to obtain a mortgage, particularly if they are not familiar with
              the most recent developments in the mortgage market, such as
              changes in rates and other factors.
              <br />
              <br />
              A residential mortgage is a loan that helps you purchase a home.
              When you want to buy a house but don't have enough money to pay
              for it all at once, a residential mortgage allows you to borrow a
              large sum of money from a bank or mortgage lender.
              <br />
              <br />
              Obtaining mortgages for your property is not any easier because
              there are numerous mortgage solutions available. We at Delta
              Mortgages will advise and direct you to the best products to meet
              your needs. Whether you want mortgage renewal or are a first-time
              home buyer, you are at the right place!
            </p>
          </Fade>
        </div>
      </section>
      <GetInTouchSection />
    </>
  );
}
