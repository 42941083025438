import { MdHomeFilled } from "react-icons/md";
import { DefaultLayout } from "../../layouts";
import { RentalPropertyMortgagesScreen, TitleSection } from "../../components";

export default function PrivateMortgages() {
  const breadCrumbs = [
    { title: <MdHomeFilled />, link: "/" },
    { title: "Services", link: "#" },
    { title: "Rental Property Mortgages", link: "#" },
  ];

  return (
    <DefaultLayout>
      <TitleSection
        title="Services"
        bg="/images/rental-property-mortgages-bg.png"
        breadCrumbs={breadCrumbs}
      />
      <RentalPropertyMortgagesScreen />
    </DefaultLayout>
  );
}
