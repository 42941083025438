const Map = () => {
  return (
    <div>
      <iframe
        title="Address"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d23077.550710200176!2d-79.7481!3d43.696128!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b15a068e7e9f5%3A0x97dd0802a13e2e1e!2sDelta%20Mortgages%20Inc.!5e0!3m2!1sen!2sus!4v1681722603618!5m2!1sen!2sus"
        width="100%"
        height="450"
        style={{ borderRadius: "16px" }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Map;
