import React from "react";
import { Button, Modal } from "../../common";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../../../utils";

type CalculatorDataType = {
  data: {
    term: string;
    ratesFrom: string;
    payment: string;
    ratesFromText: string;
    ratesUpto: string;
  };
};
const TableItem = ({ data }: CalculatorDataType) => {
  const [show, setShow] = React.useState(false);
  const navigate = useNavigate();

  const toggleModal = () => setShow(!show);
  return (
    <>
      <tr
        className="hover:bg-neutral-700 cursor-pointer"
        onClick={toggleModal}
        // onClick={() => navigate("/apply-now")}
      >
        <td className="py-2 ps-2">{data.term}</td>
        <td className="py-2 ps-2">
          <span>{data.ratesFrom}</span>
          <span>%</span>
        </td>
        <td className="py-2 ps-2">
          <span className="me-1">${formatNumber(data.payment)}</span>
          <span className="text-gray-500">/month</span>
        </td>
      </tr>
      <Modal title={data.term} open={show} toggleModal={toggleModal}>
        <div className="py-3 px-0 lg:px-16 text-center">
          <h2 className="text-4xl lg:text-6xl font-semibold mb-4">
            {data.ratesFrom}%
          </h2>
          <p className="mb-4">{data.ratesFromText}</p>
          <hr />
          <h3 className="text-2xl font-semibold my-4">
            {data.ratesFrom}% - {data.ratesUpto}%
          </h3>
          <p className="mb-3">
            If you don’t meet the requirements of our lowest advertised rate, we
            still have you covered with more low-rate options! The above range
            represents realistic rates if you don’t meet the outlined criteria.
            Certain factors, such as equity in your home and credit rating, will
            impact your final rate. At True North Mortgage, we're committed to
            providing your best-possible rate for your situation.
          </p>
          <p className="text-cred-100 text-sm italic mb-4">
            Additional premiums may apply to rentals, extended amortizations,
            non-standard properties and alternative lending options.
          </p>
          <Button variant="primary" onClick={() => navigate("/apply-now")}>
            Get Your Best Rate
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default TableItem;
