import axios, { BACKEND_URL } from "../axios";

class ApplyFormModel {
  static endPoint = "form/";

  static register(body: any) {
    return axios.post(BACKEND_URL + this.endPoint, body);
  }
}

export default ApplyFormModel;
